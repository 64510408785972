import React, { useState, useEffect, useRef } from 'react';
import './custom.css';
import MyNavbar from './Navbar';
import NavbarLogin from './Navbarlogin';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import MyOtpfield from './Otpfield'
import { useTranslation } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';



export default function MyOtpform() {

  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(false);

  const startTimer = () => {
    setIsTimerRunning(true);
    setTimer(60);
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer > 0) {
          return prevTimer - 1;
        }
        setIsTimerRunning(false);
        clearInterval(intervalId);
        return prevTimer;
      });
    }, 1000);
  };

  const [otpSubmit, onOtpSubmit] = useState('')
  const [otperr, otpError] = useState(true)
  const [otperrmsg, otpErrorMsg] = useState(true)
  const [otploader, btnLoader] = useState(true)
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const key = queryParams.get('key');

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/\D/g, '');
    newOtp[index] = formattedValue;
    setOtp(newOtp);

    if (e.target.value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };



  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  const successToast = () => {
    toast.dismiss();
    toast.success(`${translate('OTP verified successfully')} `);
  };
  const successToast1 = () => {
    toast.dismiss();
    toast.success(`${translate('OTP Send Successfully')} `);

  };
  const errorToast = (e) => {
    toast.dismiss();
    toast.error(e);
  };

  const handleOtpSubmit = () => {
    const otpValue = otp.join(''); // Convert the OTP array to a string
    onOtpSubmit(otpValue); // Pass the OTP value to the parent component
  };
  const resend = async () => {
    startTimer();

    const id = localStorage.getItem('hnhpapaemail');
    let bodyFormData = new FormData()
    bodyFormData.append('ParentEmail', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/resentotp',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {

          successToast1();
        } else {
          errorToast(res.data.message);
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });
  };

  useEffect(() => {
    if (isTimerRunning && timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer > 0) {
            return prevTimer - 1;
          }
          clearInterval(intervalId);
          return prevTimer;
        });
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isTimerRunning, timer]);

  let navigate = useNavigate()
  const verifyOtp = async () => {
    var newArray = otp.toString()
    var stringValue = newArray.replace(/,/g, '');
    var stringlength = stringValue.length;
    if (stringlength === 6) {
      const keyExists = localStorage.getItem('hnhpapaemail') !== null;
      const id = localStorage.getItem('hnhpapaemail');
      let bodyFormData = new FormData()
      bodyFormData.append('ParentEmail', id)
      bodyFormData.append('ParentOTP', stringValue)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/verifyotp',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            successToast();
            setTimeout(() => {
              if (key == 'login') {
                localStorage.setItem('hnhpapauserId', res.data.result.user.ParentID);
                localStorage.setItem('hnhpapaSchoolId', res.data.result.user.ParentSchoolID);
                navigate('/')
              } else {
                localStorage.setItem('hnhpapauserId', res.data.result.user.ParentID);
                localStorage.setItem('hnhpapaSchoolId', res.data.result.user.ParentSchoolID);
                navigate('/');
              }

            }, 2000);

          } else {
            errorToast(res.data.message);
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
        });

    } else {
      if (stringlength === 0) {
        toast.success(`${translate('Please Enter Otp')} `);

      } else {
        toast.success(`${translate('Please Enter Valid Otp')} `);

      }

    }

  };
  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <>
      <ToastContainer />
      <NavbarLogin />

      <Container >

        <Container
          className="d-flex align-items-center vh-100"
          fluid
        >

          <Row className="justify-content-center ">
            <Col md={6} lg={4} className="justify-content-center align-items-center ">
              <div style={{ borderRadius: '10px', backgroundColor: '#EFEFEF' }} className=" border border-gray-100 p-4 rounded mb-5">
                <Form>
                  <Link to="/welcome">  <img src="leftarrow.png" className="pb-3" /></Link>

                  <div style={{ fontSize: '18px' }} className="fw-bold">
                    {translate('Otp Verification')}

                  </div>
                  <div style={{ fontSize: '15px', fontWeight: '500px' }} className=" text-muted mb-4 mt-4">
                    {translate('Enter the verification code we just sent to your email address')}.
                  </div>
                  <Form.Group controlId="formBasicEmail">
                    <div className='mb-10'>
                      <div className="d-flex ">
                        {otp.map((digit, index) => (
                          <input
                            key={index}
                            type="text"
                            id={`otp${index + 1}`}
                            className={`${otpError ? "bg-red-50 !border-red-600" : " border border-gray-300"} ${"otp-input pl-10 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-10 p-2.5"}`} style={{ width: "25%", padding: "10px", margin: "5px", borderRadius: "8px" }}
                            // className="otp-input pl-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            maxLength={1}
                            value={digit}
                            onChange={(e) => handleOtpChange(e, index)}
                            onKeyDown={(e) => handleKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                          />
                        ))}

                      </div>
                      {otpErrorMsg && <div className="text-red-600 text-xs mt-2">{otpErrorMsg}</div>}
                    </div>
                  </Form.Group>




                  <Button
                    className="fw-bold mt-4  text-white"
                    style={{
                      width: '100%',
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '9px',
                    }}
                    onClick={verifyOtp}
                  >
                    {translate('Verify')}

                  </Button>
                  <div className="text-center mt-4">
                    <p className="link-no-underline ">{translate('Did not receive code')}?
                      {isTimerRunning ? (
                        <span style={{ color: '#E85C33', cursor: 'not-allowed' }}>
                          {translate('Resend')} {translate('in')} {timer} {translate('seconds')}
                        </span>
                      ) : (
                        <span style={{ color: '#E85C33', cursor: 'pointer' }} onClick={resend}>
                          {translate('Resend')}
                        </span>
                      )}
                    </p>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
};


