import React, { useEffect,useState } from 'react';
import './custom.css';
import MyNavbar from './Navbar';
import NavbarLogin from './Navbarlogin';

import { Container, Row, Col, Form, Button,Modal } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';




export default function MyNewpw(){

  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisiblee, setPasswordVisiblee] = useState(false);
    const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [ password , setPassword ] = useState('')
    const [ confirmPassword ,setconfirmPassword ] = useState('')
    const [ verifypassword , setVerifyPassword ] = useState(true)
    const [ verifyconfirmPassword ,setVerifyconfirmPassword ] = useState(true)
    const [ verifypasswordValue , setVerifyPasswordValue ] = useState('')
    const [email, setLocalStorageValue] = useState('');

    useEffect(() => {
      const keyExists = localStorage.getItem('hnhpapaemail') !== null;
      if (keyExists) {
        const storedValue = localStorage.getItem('hnhpapaemail');
        setLocalStorageValue(storedValue);
      } else {
      }
    }, []);

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
    const togglePasswordVisibilityy = () => {
      setPasswordVisiblee(!passwordVisiblee);
    };
    const handleClosePasswordSavedModal = () => {
      setPasswordChangedSuccessfully(false);
    };

   


    const handleCloseModal = () => {
      setShowModal(false);
    };
  
    const handleShowModal = () => {
      setShowModal(true);
    };
  



  const handleOpenChangePasswordModal = () => {
    handleCloseModal();
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
      setTimeout(() => {
          setPasswordChangedSuccessfully(true);
          setShowChangePasswordModal(false);
        }, 20);
      setPasswordChangedSuccessfully(true);
    setShowChangePasswordModal(false);
  };
  const checkPassword = (e) =>{
    setPassword(e.target.value);
    setVerifyPassword(true);
    // valid(true)
  }
  const checkconfirmPassword = (e) =>{
    setconfirmPassword(e.target.value);
    setVerifyconfirmPassword(true);
    // valid(true)
  }

  const handleSavePassword = () => {
  
    setTimeout(() => {
      setPasswordChangedSuccessfully(true);
      setShowChangePasswordModal(false);
    }, 200);
  };

  let navigate=useNavigate()
  const resetPassword = async () => {
  
        if((password=="") || (confirmPassword==""))
        {
      
          if(password=="")
          {
            setVerifyPassword(false);
          }
          if(confirmPassword=="")
          {
            setVerifyconfirmPassword(false);
            setVerifyPasswordValue(`${translate('Enter Confirm Password')} `);

          }
          
        }else{
        if(password !=confirmPassword)
        {
          setVerifyconfirmPassword(false);
          setVerifyPasswordValue(`${translate('Password not matching')} `);

        }else{
              let response = await axios({
                method : 'post',
                url : `${Base_Url}/resetpassword`,
                data : {
                  ParentEmail : email,
                  ParentPassword : password,
                }
            })
            .then((res)=>{
                console.log('response',res);
                if(res.data.status == true ){
                  localStorage.removeItem('hnhpapauserId');
                  navigate('/welcome')
                }else{
                  // valid(false)
                  // validvalue(res.data.message)
                }
            }).catch((error)=>{
              console.error('Error',error)
            })
          }
        }
            
      }

    return (
      <>
          <NavbarLogin />
          <Container
        className="d-flex align-items-center vh-100"
        fluid
      >
          <Container className="">
            <Row className="justify-content-center ">
              <Col md={6} lg={4} className="justify-content-center align-items-center">
              <div style={{ borderRadius: '10px', backgroundColor: '#EFEFEF' }}className=" border border-gray-100 p-4 rounded mb-5">
                <Form>
                <Link to="/security">  <img src="leftarrow.png" className="pb-3"/></Link>

                  <div style={{ fontSize: '18px' }} className="fw-bold">
                     {translate('Set New Password')}

                  </div>
                  <Form.Group className="mt-3" controlId="formBasicPassword">
                  <Form.Label style={{ fontSize: '15px' }}>{translate('New Password')}</Form.Label>
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <Form.Control
                      style={{ padding: '10px', borderRadius: '9px',fontSize: '15px' }}
                      type={passwordVisible ? 'text' : 'password'}
                      placeholder={translate('Enter Password')}

                      onChange={(e)=>checkPassword(e)}
                      value={password}
                    />
                    <div
                    style={{
                      position: 'absolute',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      right: '10px',
                      cursor: 'pointer',
                    }}
                    onClick={togglePasswordVisibility}
                  >
                  {passwordVisible ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                      <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
                      <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
                    </svg>
                  ) : (
                  
                    <img src="eye.png" alt="Hide Password" />

                  )}
                </div>
                  </div>
                  {
                    verifypassword  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'5px'}}>{translate('Enter Password')}</p> 
                  }
                </Form.Group>



                <Form.Group className="mt-3" controlId="formBasicPassword">
                  <Form.Label style={{ fontSize: '15px' }}>{translate('Confirm New Password')}</Form.Label>
                  <div className="d-flex align-items-center" style={{ position: 'relative' }}>
                    <Form.Control
                      style={{ padding: '10px', borderRadius: '9px',fontSize: '15px' }}
                      type={passwordVisiblee ? 'text' : 'password'}
                      placeholder={translate('Confirm Password')}
                      onChange={(e)=>checkconfirmPassword(e)}
                      value={confirmPassword}
                    />
                    <div
          style={{
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            right: '10px',
            cursor: 'pointer',
          }}
          onClick={togglePasswordVisibilityy}
        >
          {passwordVisiblee ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
  <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z"/>
  <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z"/>
</svg>
          ) : (
            <img src="eye.png" alt="Hide Password" />

          )}
        </div>
                  </div>
                  {
                    verifyconfirmPassword  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'5px'}}>{verifypasswordValue}</p> 
                  }
                  
                </Form.Group>
                
                  
  
  
                  <Button
                    className="fw-bold mt-5  text-white"
                    onClick={resetPassword}
                    // onClick={handleCloseChangePasswordModal}

                    style={{
                      width: '100%',
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '9px',
                    }}
                  >
                    {translate('Save Password')}
                  </Button>
                
                </Form>
                </div>
              </Col>
            </Row>

            <Modal
  dialogClassName="modal-dialog-centered" 
  centered  
  show={passwordChangedSuccessfully}
  onHide={handleClosePasswordSavedModal}
>
         <div className="my-4">
          <div className="text-center mt-5"><img src="save.png"></img></div>
          <div className="text-center mt-3 "style={{ fontSize: '20px',fontWeight:'bold' }}>{translate('Success')}
</div>
         <div className="text-center my-3">{translate('Password Saved')} <br/>{translate('Successfully')}</div>
         </div>
        </Modal>
          </Container>
        </Container>



      </>
    );
};


