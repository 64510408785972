import React, { useState } from 'react';
import './custom.css';
import NavbarLogin from './Navbarlogin';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import MyOtpfield from './Otpfield';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';

import { useTranslation } from './LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';



export default function MyPwreset(){
  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }

    const [passwordVisible, setPasswordVisible] = useState(false);

    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
    const [ email , setEmail ] = useState('')
    const [ verfiyEmail , setVerifyEmail ] = useState(true)
    const [ verfiyValue , setVerifyValue ] = useState('')
    const [ invalidCredentails,valid ] = useState(true)
    const [ invalidValue,validcredentails ] = useState('')

    const checkEmail = (e) =>{
      setEmail(e.target.value);
      setVerifyEmail(true);
      valid(true)
    }
    const successToast = (e) => {
      toast.dismiss();
      toast.success(e);
    };
    const errorToast = (e) => {
      toast.dismiss();
      toast.error(e);
    };
    let navigate=useNavigate()
  const signin = async () => {
  
    if(email=="")
    {
      setVerifyEmail(false)
      setVerifyValue(`${translate('Please Enter Email')} `);

      validcredentails('')

    }else if(email !=""){

          let emailveri = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          if(email.match(emailveri))
          {
            setVerifyEmail(true)
            setVerifyValue('');
            
            let response = await axios({
                  method : 'post',
                  url : `${Base_Url}/sentpasswordotp`,
                  data : {
                    ParentEmail : email,
                  }
              })
              .then((res)=>{
                  console.log('response',res);
                  if(res.data.status == true ){
                    localStorage.setItem('hnhpapaemail',email);
                    validcredentails('')
                    successToast(`${translate('Security Code Sent to Email')} `);
                    setTimeout(() => {
                      navigate('/security')
                    }, 2000);
                    
                  }else{
                    valid(false)
                    validcredentails(res.data.message)
                  }
              }).catch((error)=>{
                console.error('Error',error)
              })
           

          }else{
            
            setVerifyEmail(false)
            setVerifyValue(`${translate('Please Enter Valid Value')} `);

            validcredentails('')
          }

    }else{
          
    }
        
}
  
    return (
      <>
       <ToastContainer />
          <NavbarLogin />
          <Container
        className="d-flex align-items-center vh-100"
        fluid
      >
          <Container className="">
            <Row className="justify-content-center ">
              <Col md={6} lg={4} className="justify-content-center align-items-center ">
                <div className="">
              <div style={{ borderRadius: '10px', backgroundColor: '#EFEFEF' }}className=" border border-gray-100 p-4 rounded mb-5">
                <Form>
                <Link to="/welcome">  <img src="leftarrow.png" className="pb-3"/></Link>

                  <div style={{ fontSize: '18px' }} className="fw-bold">
                    {translate('Password reset')}
                  </div>
                  <div style={{ fontSize: '15px',fontWeight:'500px' }} className=" text-muted mb-4 mt-4">
                  {translate('Enter the registered email to reset password')}

                  </div>
                  <Form.Group controlId="formBasicEmail">
                  <Form.Label style={{ fontSize: '16px' }}>{translate('Email')}
</Form.Label>
                  <Form.Control
                    className={(verfiyEmail ? false : 'errorField')}
                    style={{ padding: '10px', borderRadius: '9px' }}
                    type="email"
                    placeholder={translate('Enter Email')}
                    onChange={(e)=>checkEmail(e)}
                    value={email}
                  />
                  {
                    verfiyEmail  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'10px'}}>{verfiyValue}</p> 
                  }
                </Form.Group>
                
                  
  
  {/* <Link to="/security"> */}
                  <Button
                    className="fw-bold mt-5  text-white"
                    style={{
                      width: '100%',
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '9px',
                    }}
                    onClick={signin}
                  >
                    {translate('Continue')}
                  </Button>
                  {/* </Link> */}
                  {
                    invalidCredentails  ? false : <p style={{color:'red',fontSize:'14px',marginTop:'5px',marginLeft:'74px'}}>{invalidValue}</p> 
                  }
                </Form>
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
};


