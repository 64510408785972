import React, { useEffect, useState } from 'react'

import './custom.css';
import MyNavbar from './Navbar';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';
import Loader from './Loader';
import 'react-toastify/dist/ReactToastify.css';

import { ToastContainer, toast } from 'react-toastify'


export default function MyAccount() {
  const [loading, setLoading] = useState(false)
  const { translate, changeLanguage,notiCnt,setNotification } = useTranslation();
  const [datas, setData] = useState([])

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [message, setMessage] = useState('');

  const [button, SetButton] = useState(false);
  const [parentName, setParentName] = useState('');
  const [parentEmail, setParentEmail] = useState('');
  const [parentPhone, setParentPhone] = useState('');
  const [parentImage, setParentImage] = useState('');
  const [parentMessage, setParentMessage] = useState('');
  const [errorCurrentPassword, setErrorCurrentPassword] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [verifyPhoneNo, setVerifyPhoneNo] = useState('');
  const [modalImage, setModalImage] = useState('');
  const [invalidCredentails, valid] = useState(true)
  const [invalidvalue, validvalue] = useState('')
  const [showCreateChildModal, setShowCreateChildModal] = useState(false);
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);

  const [verifyname, setVerifyname] = useState(true)
  const [verifyemail, setVerifyEmail] = useState(true)
  const [verifyphoneNo, seVerifytphoneNo] = useState(true)
  const [verifyemailregx, setVerifyEmailRegx] = useState(true)
  const [verifyphoneNoStatus, setVerifyphoneNoStatus] = useState(true)
  const [verifyNameStatus, setVerifyNameStatus] = useState(true)
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible1, setPasswordVisible1] = useState(false);
  const [passwordVisiblee, setPasswordVisiblee] = useState(false);
  const [verifyconfirmPassword, setVerifyconfirmPassword] = useState(true)
  const [verifypassword, setVerifyPassword] = useState(true)
  const [verifypassword1, setVerifyPassword1] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [verifyNewPassword, setVerifyNewPassword] = useState(true)
  const [verifyCurrentPassword, setVerifyCurrentPassword] = useState(true)
  const [verifyConfirmPassword, setVerifyConfirmPassword] = useState(true)
  const [image, setImage] = useState('')
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [manage, setManage] = useState('profile')
  const handleSaveChanges = () => {
    setIsSaveButtonClicked(true);
  };


  const [profileImage, setProfileImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [passwordChangedSuccessfully, setPasswordChangedSuccessfully] = useState(false);
  const [parentId, setLocalStorageValue] = useState('');
  const [parentDetails, setParentDetails] = useState('');
  const [errorMessages, setErrorMessages] = useState({
    newPassword: '',
    currentPassword: '',
    confirmPassword: ''
  });


  const togglePasswordVisibilityy = () => {
    setPasswordVisiblee(!passwordVisiblee);
  };
  const togglePasswordVisibility1 = () => {
    setPasswordVisible1(!passwordVisible1);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const successToast = (e) => {
    toast.dismiss();
    toast.success(e);
  };
  const errorToast = (e) => {
    toast.dismiss();
    toast.error(e);
  };


  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
      setModalImage(file)
      setImage(file)
    }
  };



  const checkPhone = (e) => {
    const phoneValue = e.target.value;
    // console.log('Phone value:', phoneValue);  // Log the value to verify
    setParentPhone(phoneValue);

    const phoneRegex = /^\d{10,14}$/;
    setVerifyPhoneNo(phoneRegex.test(phoneValue));  // Ensure it's set only once
  };

  const handleConfirmLogoutHide = () => {
    setShowConfirmLogoutModal(false);
  }


  const updateProfile = async () => {

    if (parentEmail != "") {
      let emailveri = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (parentEmail.match(emailveri)) {
        setVerifyEmailRegx(true)
      } else {
        setVerifyEmailRegx(false)

      }
    }
    // if(parentPhone !="")
    // {
    //   if(parentPhone.length<10)
    //   {
    //     setVerifyphoneNoStatus(false)
    //     seVerifytphoneNo(true);

    //   }else{
    //     setVerifyphoneNoStatus(true)
    //   }
    // }
    if (parentEmail == "" || parentPhone == "" || parentName == "") {
      if (parentEmail == "") {
        setVerifyEmail(false);
        setVerifyEmailRegx(true)
      }
      if (parentPhone == "") {
        seVerifytphoneNo(false);
        setVerifyphoneNoStatus(true)
      }
      if (parentName == "") {
        setVerifyname(false);
        setVerifyNameStatus(true)
      }
      return false;
    }
    SetButton(true)
    const formData = new FormData();
    formData.append('ParentName', parentName);
    formData.append('ParentEmail', parentEmail);
    formData.append('ParentNumber', parentPhone);
    formData.append('ParentID', parentId);
    if (image != '') {
      formData.append('ParentImage', image);
    }

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/editparents`,
      data: formData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
      // data : {
      //   ParentName : parentName,
      //   ParentEmail : parentEmail,
      //   ParentNumber : parentPhone,
      //   ParentID : parentId,
      // }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          alert(`${translate('Profile Updated Successfully')} `);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          setIsSaveClicked(true);
          SetButton(false)
        } else {
          valid(false)
          validvalue(res.data.message)
          errorToast(res.data.message);
          SetButton(false)
        }
      }).catch((error) => {
        console.error('Error', error)
        // setLoading(false)

      })

  }

  const checkname = (e) => {
    setParentName(e.target.value);
    setVerifyname(true);
  }
  const checkNewPassword = (e) => {
    setNewPassword(e.target.value);
    setVerifyNewPassword(true);
  }
  const checkCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    setVerifyCurrentPassword(true);
  }
  const checkConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setVerifyConfirmPassword(true);
  }
  const checkEmail = (e) => {
    setParentEmail(e.target.value);
    setVerifyEmail(true);
    setVerifyEmailRegx(true)
  }


  const checkName = (e) => {
    setParentName(e.target.value);

    const input = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(input) || input === '') {
      setParentName(input);
    }
  }

  const handleCreateChild = () => {
    setShowModal(false);

    setShowCreateChildModal(true);
  };

  const handleConfirmLogout = () => {
    setShowModal(false);

    setShowConfirmLogoutModal(true);
  };

  const savePassword = async () => {

    const newErrorMessages = {};

    if (!newPassword) {
      newErrorMessages.newPassword = translate('Please enter new password');

    }

    if (!currentPassword) {
      newErrorMessages.currentPassword = translate('Please enter your current password');
    }
    if (!confirmPassword) {
      newErrorMessages.confirmPassword = translate('Please enter your current password again');
    }





    setErrorMessages(newErrorMessages);

    setVerifyNewPassword(newPassword !== '');
    setVerifyCurrentPassword(currentPassword !== '');
    setVerifyConfirmPassword(confirmPassword !== '');

    if (newPassword === '' || currentPassword === '' || confirmPassword === '') {
      return; // Don't proceed if any field is empty
    }

    if (newPassword !== confirmPassword) {
      // Show alert for password mismatch
      // console.log(newPassword,confirmPassword)
      alert('New Password & Confirm New Password Mismatch');
      return;
    }
    if (newPassword == "" || currentPassword == "" || confirmPassword == "") {

      if (newPassword == "") {
        setVerifyNewPassword(false)
      }
      if (currentPassword == "") {
        setVerifyCurrentPassword(false)
      }
      if (confirmPassword == "") {
        setVerifyConfirmPassword(false)
      }

    } else {
      if (newPassword == confirmPassword) {

        let bodyFormData = new FormData()
        const storedValue = localStorage.getItem('hnhpapaemail');
        bodyFormData.append('ParentEmail', storedValue)
        bodyFormData.append('ParentPassword', newPassword)
        let responce = await axios({
          method: 'post',
          url: Base_Url + '/resetpassword',
          data: bodyFormData,
          dataType: 'json',
          headers: { "Content-Type": "multipart/form-data" }
        })
          .then(async (res) => {
            if (res.data.status === true) {
              toast.success('Password Changed Successfully');
              handleCloseModal()
              // setTimeout(() => {
              //   window.location.reload();
              // }, 1000);
            } else {
              setVerifyCurrentPassword(true)
              setVerifyConfirmPassword(true)

              {
                errorMessages.newPassword && (
                  <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                    {errorMessages.newPassword}
                  </div>
                )
              }

            }
          })
          .catch((err) => {
            console.log('Error fetching data:', err);
            alert(err)
          });
      } else {

      }
    }


  }

  const logout = () => {
    localStorage.removeItem('hnhpapauserId');
    localStorage.removeItem('hnhpapaSchoolId');
    localStorage.removeItem('hnhpapaemail');
    localStorage.removeItem('hnhpapaschoolName');
    localStorage.removeItem('hnhpapacartCount');
    localStorage.removeItem('hnhpapaparentimage');
    navigate('/welcome');
  }

  let navigate = useNavigate()
  const getDetails = () => {
    setLoading(true)
    const keyExists = localStorage.getItem('hnhpapauserId') !== null;
    if (keyExists) {
      const storedValue = localStorage.getItem('hnhpapauserId');
      setLocalStorageValue(storedValue);
      viewParent(storedValue);
      setIsSaveClicked(false)
    } else {
      navigate('/welcome');
    }
  }
  const viewParent = async (id) => {

    let bodyFormData = new FormData()
    bodyFormData.append('ParentID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/viewparents',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setParentDetails(res.data.result)
          setParentName(res.data.result.ParentName)
          setParentEmail(res.data.result.ParentEmail)
          setParentPhone(res.data.result.ParentNumber)
          setParentImage(res.data.result.ParentImage)
          setParentMessage(res.data.ParentMessage)
          setNotification(res.data.notification)
          // console.log(res.data.notification)
          localStorage.setItem('hnhpapaparentimage', res.data.result.ParentImage);
          setLoading(false)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });
  }

  // console.log(isSaveClicked, "chan")
  useEffect(() => {
    getDetails();
  }, [isSaveClicked])


  const [isSecondBoxVisible, setSecondBoxVisibility] = useState(false);


  const [isEditing, setEditing] = useState(false);

  const handleEditButtonClick = () => {
    setEditing(!isEditing);
  };





  const handleCloseModal = () => {
    setShowModal(false);
    setShowChangePasswordModal(false);
    setNewPassword('')
    setConfirmPassword('')
    setCurrentPassword('')
    setPasswordVisible1(false)
    setPasswordVisible(false)
    setPasswordVisiblee(false)
    setErrorMessages({
      newPassword: '',
      currentPassword: '',
      confirmPassword: ''
    })

  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleOpenChangePasswordModal = () => {
    handleCloseModal();
    setShowChangePasswordModal(true);
  };

  const handleCloseChangePasswordModal = () => {
    setTimeout(() => {
      setPasswordChangedSuccessfully(true);
      setShowChangePasswordModal(false);
    }, 20);
    setPasswordChangedSuccessfully(true);
    setShowChangePasswordModal(false);
  };

  const handleSavePassword = () => {

    setTimeout(() => {
      setPasswordChangedSuccessfully(true);
      setShowChangePasswordModal(false);
    }, 200);
  };

  const handleClosePasswordSavedModal = () => {
    setPasswordChangedSuccessfully(false);
  };


  const boxStyletwo = {
    borderColor: '#303A5B',
    borderRadius: '5px'

  };
  const boxStyle = {
    padding: '2px',
    borderRadius: '5px',
  };

  const schoolName = {
    fontSize: '15px',
  };

  const placeColor = {
    fontSize: '15px',
  };

  const buttoncolor = {
    backgroundColor: '#F17C38',
    border: 'none',
  };
  const handleKeyPress = (e) => {
    const keyCode = e.which || e.keyCode;
    if (!(keyCode >= 65 && keyCode <= 90) && !(keyCode >= 97 && keyCode <= 122) && keyCode !== 32) {
      e.preventDefault();
    }
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    setErrorMessages((prevErrors) => ({ ...prevErrors, newPassword: '' })); // Clear error for newPassword
    setVerifyNewPassword(true)

  };

  const handleCurrentPasswordChange = (e) => {
    setCurrentPassword(e.target.value);
    setErrorMessages((prevErrors) => ({ ...prevErrors, currentPassword: '' })); // Clear error for currentPassword
    setVerifyCurrentPassword(true)

  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setErrorMessages((prevErrors) => ({ ...prevErrors, confirmPassword: '' })); // Clear error for confirmPassword
    setVerifyConfirmPassword(true)

  };


  const handlePhoneKeyPress = (e) => {
    const keyCode = e.which || e.keyCode;

    // Allow only numbers (0-9)
    if (!(keyCode >= 48 && keyCode <= 57)) {
      e.preventDefault();
    }

    // Limit to 10 digits
    const phoneNumber = e.target.value.replace(/[^0-9]/g, '');
    if (phoneNumber.length > 15) {
      e.preventDefault();
    }
    if (phoneNumber.length < 9) {
      seVerifytphoneNo(false)
    }
    else{
      seVerifytphoneNo(true) 
    }
    setParentPhone(phoneNumber);
  };

  // const viewNoti = async () => {

  //   let bodyFormData = new FormData()
  //   const parentId = await localStorage.getItem('hnhpapauserId');
  //   bodyFormData.append('ParentID', parentId)

  //   let responce = await axios({
  //     method: 'post',
  //     url: Base_Url + '/notification',
  //     data: bodyFormData,
  //     dataType: 'json',
  //     headers: { "Content-Type": "multipart/form-data" }
  //   })
  //     .then(async (res) => {
  //       if (res.data.status === true) {
  //         setMessage(res.data.message)
  //         setData(res.data.result);

  //       }
  //     })
  //     .catch((err) => {
  //       console.log('Error fetching data:', err);
  //     });
  // }


  return (
    <div>
      {loading ? (
        <Loader />
      ) : (

        <Container>
          <ToastContainer limit={1} />

          <MyNavbar />

          <div className="my-4">
            <Row className=" ">
              <Col xs={5} sm={7} md={5} lg={3} xl={3}>

                <Link to="/" className="link-no-underline">
                  <img src="leftarrow.png" alt="School Logo" className="pe-2" />
                  {translate('Profile')}
                </Link>
              </Col>
            </Row>
          </div>
          <Row>
            <Col lg={3} xl={3} md={12} sm={12} xs={12}>
              <div onClick={() => setManage("profile")} className={manage == 'profile' ? "border border-gray-100 ps-1 py-2" : 'my-3'} style={manage === 'profile' ? { background: 'rgb(239, 239, 239)', borderRadius: '8px' } : {}}  >
                <img src="pro.png" alt="Profile" className="pe-2" />{translate('Profile')}
              </div>

              <div style={manage === 'terms' ? { background: 'rgb(239, 239, 239)', borderRadius: '8px' } : {}} >

                <Link to="/terms" className="link-no-underline">
                  <div onClick={() => { setManage("terms") }} className={manage == 'terms' ? "border border-gray-100 ps-2  py-2" : 'my-3'}>
                    <img src="terms.png" alt="Terms & Conditions" className="pe-2" />  {translate('Terms & Conditions')} 
                  </div>
                </Link>
              </div>
              <div>
                <Link to="/notifications" className="link-no-underline pe-2" >
                  <img src="notify.png" alt="Notification" className="pe-2" />
                  <span onClick={() => { handleCreateChild(); setManage("notification") }} className={manage == 'notification' ? "border border-gray-100 ps-2 py-2" : 'my-4'}
                    style={manage === 'notification' ? { background: 'rgb(239, 239, 239)', borderRadius: '8px' } : {}} >
                    {translate('Notification')}
                  </span>
                  {notiCnt > 0 ? <span style={{
                    marginTop: '-34px', display: 'block', marginLeft: '-8px', color: 'white', padding: '-11px',
                    borderRadius: '24px', textAlign: 'center', background: 'red', width: '16px', height: '18px', fontSize: '12px'
                  }}>
                    {notiCnt}
                  </span> : null}

                </Link>
              </div>
              <div className="my-4">
                <Link to="/history" className="link-no-underline">   <img src="terms.png" alt="Logout" className="pe-2" />
                  {translate('Order History')}
                </Link>
              </div>
              <div className="my-4">
                <Link className="link-no-underline" onClick={handleConfirmLogout}>   <img src="logout.png" alt="Logout" className="pe-2" />         {translate('Logout')}
                </Link>
              </div>
            </Col>
            <Col lg={8} xl={9} md={12} sm={12} xs={12} className="">

              <Row>
                {isEditing ? (
                  <div className="px-2">

                    <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                      <Row>
                        <Col lg={8} xl={9} md={6} sm={7} xs={4}>

                          <div className="mb-3">
                            <label htmlFor="profileUpload" className="profile-uploader">
                              <input
                                type="file"
                                id="profileUpload"
                                accept="image/*"
                                style={{ display: 'none' }}
                                onChange={handleProfileImageChange}
                              />
                              <div className="profile-image-container position-relative">
                                {profileImage ? (
                                  <img src={profileImage} alt="profileImage" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                                ) : (
                                  <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                                    <img src={Image_Url + parentImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />

                                  </div>
                                )}
                                <span className="position-absolute bottom-0 end-0" style={{ top: '60%', left: '60%' }}>

                                  <img src="pencil.png" style={{ cursor: 'pointer', backgroundColor: 'transparent', borderRadius: '50%', }} />
                                </span>
                              </div>
                            </label>
                          </div>


                        </Col>
                        <Col lg={4} xl={3} md={6} sm={5} xs={8} className="text-end">
                          <Button className=""
                            onClick={updateProfile}
                            style={{
                              width: '60%',
                              backgroundColor: '#E85C33',
                              borderColor: '#E85C33',
                              color: '#FFFFFF',
                              fontSize: '14px',
                              paddingLeft: '1px',
                              paddingRight: '1px',
                              shadow: 'none'
                            }}
                          >{translate('Save Changes')}

                          </Button>

                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={4} sm={12} xs={12}>

                          <div className="mb-2">
                            <label htmlFor="input1" className="form-label">
                              {translate('Name')}
                            </label>

                            <input

                              type="text"
                              className="form-control py-3"
                              id="name"
                              placeholder="Jerald Jebha"
                              value={parentName}
                              onChange={(e) => checkName(e)}
                              onKeyPress={(e) => handleKeyPress(e)}


                            />
                            {
                              verifyname ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Full Name')} </p>
                            }
                          </div>
                        </Col>

                        <Col md={4} sm={12} xs={12}>
                          <div className="mb-2 ">
                            <label htmlFor="email" className="form-label">
                              {translate('Email')}
                            </label>
                            <input
                              type="text"
                              className="form-control py-3"
                              id="input2"
                              placeholder="jeraldjebhat@gmail.com"
                              value={parentEmail}
                              onChange={(e) => checkEmail(e)}


                            />
                            {
                              verifyemail ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Email')}</p>
                            }
                            {
                              verifyemailregx ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Valid Email')}</p>
                            }

                          </div>
                        </Col>

                        <Col md={4} sm={12} xs={12}>
                          <div className="mb-2">
                            <label htmlFor="input3" className="form-label">
                              {translate('Phone No')}
                            </label>

                            <input
                              type="text"
                              className={`form-control py-3 `}
                              id="phone"
                              value={parentPhone}
                              onChange={(e) => checkPhone(e)}
                              onKeyPress={(e) => handlePhoneKeyPress(e)}
                            />


                            {
                              verifyphoneNo ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter valid Phone No')} </p>
                            }

                          </div>
                        </Col>
                      </Row>

                    </div>
                  </div>
                ) : (
                  <div className="px-2">
                    <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3  ">
                      <Row>
                        <Col lg={6} xl={9} md={6} sm={6} xs={4}>
                          <img src={Image_Url + parentImage} alt="School Logo" style={{ width: '74px', height: '74px', borderRadius: '74px' }} />

                        </Col>
                        <Col lg={6} xl={3} md={6} sm={6} xs={8} className="text-end">
                          <Button className="" onClick={handleEditButtonClick} style={{
                            width: '40%',
                            backgroundColor: 'white',
                            borderColor: '#C5C5C5',
                            color: 'black',
                            fontSize: '14px',
                            paddingLeft: '1px',
                            paddingRight: '1px',
                            shadow: 'none'
                          }}>{translate('Edit')}


                            <img src="pencil.png" className="ps-2" />
                          </Button>


                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={4} sm={12} xs={12}>
                          <div className="mb-2">
                            <label htmlFor="input1" className="form-label">
                              {translate('Name')}
                            </label>
                            <input style={{ backgroundColor: "#EFEFEF" }}

                              type="text"
                              className="form-control  py-3"
                              id="name"
                              placeholder="Jerald Jebhat"
                              value={parentName}

                              readOnly
                            />
                          </div>
                        </Col>

                        <Col md={4} sm={12} xs={12}>
                          <div className="mb-2">
                            <label htmlFor="email" className="form-label ">
                              {translate('Email')}
                            </label>
                            <input style={{ backgroundColor: "#EFEFEF" }}
                              type="text"
                              className="form-control py-3"
                              id="input2"
                              placeholder="jeraldjebhat@gmail.com"
                              readOnly
                              value={parentEmail}

                            />
                          </div>
                        </Col>

                        <Col md={4} sm={12} xs={12}>
                          <div className="mb-2">
                            <label htmlFor="input3" className="form-label ">
                              {translate('Phone No')}
                            </label>
                            <input style={{ backgroundColor: "#EFEFEF" }}

                              type="text"
                              className="form-control py-3"
                              id="phone"
                              placeholder="999456789342"
                              readOnly
                              value={parentPhone}

                            />
                          </div>
                        </Col>
                      </Row>

                    </div>
                  </div>


                )}
              </Row>









              <div className="px-2">

                <Row>
                  <button className="custom-border my-1 mt-3 py-3 px-3">
                    <div className="text-center " style={{ color: "#303A5B" }} onClick={handleOpenChangePasswordModal}>{translate('Change Password')}</div>
                  </button>
                </Row>

              </div>

            </Col>

          </Row>



          <Modal dialogClassName="modal-dialog-centered"
            centered show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '16px', border: 'none' }}>
                {translate('Edit Profile')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ fontSize: '15px' }}>
              <div className="mb-3">
                <label htmlFor="profileUpload" className="profile-uploader">
                  <input
                    type="file"
                    id="profileUpload"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleProfileImageChange}
                  />
                  <div className="profile-image-container">
                    {profileImage ? (
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="profile-image rounded-circle"
                        style={{ width: '65px', height: '65px' }}
                      />
                    ) : (
                      <div
                        className="profile-placeholder rounded-circle"
                        style={{ width: '65px', height: '65px' }}
                      >
                        <img
                          src="childprofile.png"
                          alt="profileImage"
                          style={{ width: '65%', height: '65%' }}
                        />
                      </div>
                    )}
                  </div>
                </label>
              </div>

              <Form>
                <Form.Group controlId="formName" className="mb-3">
                  <Form.Label>           {translate('Name')}
                  </Form.Label>
                  <Form.Control type="text" placeholder="Javelin Jev" />
                </Form.Group>

                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label> {translate('Email')}</Form.Label>
                  <Form.Control type="email" placeholder="abc@gmail.com" />
                </Form.Group>

                <Form.Group controlId="formPhoneNo" className="mb-3">
                  <Form.Label>{translate('Phone No')}</Form.Label>
                  <Form.Control type="text" placeholder="9876543213" />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={handleOpenChangePasswordModal}
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  borderColor: 'black',
                  color: 'black',
                }}
              >

                {translate('Change Password')}

              </Button>

              <Button
                onClick={handleCloseModal}
                style={{
                  width: '100%',
                  backgroundColor: '#E85C33',
                  borderColor: '#E85C33',
                }}
                className="mt-4"
              >
                {translate('Save Changes')}

              </Button>
            </Modal.Footer>
          </Modal>





          <Modal dialogClassName="modal-dialog-centered"
            centered show={showChangePasswordModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '16px', border: 'none' }}>     {translate('Change Password')}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Form>



                <Form.Group controlId="currentPassword" className="mb-3">
                  <Form.Label>{translate('Current Password')}</Form.Label>

                  <div style={{ position: 'relative' }}>
                    <Form.Control
                      className={verifyCurrentPassword ? '' : 'errorField'}
                      type={passwordVisiblee ? 'text' : 'password'}
                      onChange={handleCurrentPasswordChange}
                      value={currentPassword}
                    //onFocus={() => setErrorMessages((prevErrors) => ({ ...prevErrors, currentPassword: '' }))}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={togglePasswordVisibilityy}
                    >
                      {passwordVisiblee ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style={{ marginRight: '3px' }}>
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      ) : (
                        <img src="eye.png" alt="Hide Password" />

                      )}
                    </div>
                  </div>
                  <div style={{ position: 'relative' }}>
                    {errorCurrentPassword && <div style={{ color: 'red', fontSize: '12px' }}>{errorCurrentPassword}</div>}
                  </div>
                  {errorMessages.currentPassword && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                      {errorMessages.currentPassword}
                    </div>
                  )}
                </Form.Group>







                <Form.Group controlId="newPassword" className="mb-3">
                  <Form.Label>{translate('New Password')}</Form.Label>
                  <div style={{ position: 'relative' }}>
                    <Form.Control
                      className={verifyNewPassword ? '' : 'errorField'}
                      type={passwordVisible ? 'text' : 'password'}
                      onChange={handleNewPasswordChange}
                      value={newPassword}
                    //onFocus={() => setErrorMessages((prevErrors) => ({ ...prevErrors, newPassword: '' }))}
                    />

                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={togglePasswordVisibility}
                    >
                      {passwordVisible ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style={{ marginRight: '3px' }} >
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      ) : (
                        <img src="eye.png" alt="Hide Password" />

                      )}

                    </div>
                  </div>
                  {errorNewPassword && <div style={{ color: 'red', fontSize: '12px' }}>{errorNewPassword}</div>}
                  {errorMessages.newPassword && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                      {errorMessages.newPassword}
                    </div>
                  )}
                </Form.Group>

                <Form.Group controlId="confirmNewPassword" className="mb-3">
                  <Form.Label>{translate('Confirm New Password')}</Form.Label>
                  <div style={{ position: 'relative' }}>

                    <Form.Control
                      className={verifyConfirmPassword ? '' : 'errorField'}
                      type={passwordVisible1 ? 'text' : 'password'}
                      onChange={handleConfirmPasswordChange}
                      value={confirmPassword}
                    //onFocus={() => setErrorMessages((prevErrors) => ({ ...prevErrors, confirmPassword: '' }))}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        right: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={togglePasswordVisibility1}
                    >
                      {passwordVisible1 ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style={{ marginRight: '3px' }}>
                          <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                          <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                        </svg>
                      ) : (
                        <img src="eye.png" alt="Hide Password" />

                      )}
                    </div>
                  </div>
                  {errorConfirmPassword && <div style={{ color: 'red', fontSize: '12px' }}>{errorConfirmPassword}</div>}
                  {errorMessages.confirmPassword && (
                    <div style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>
                      {errorMessages.confirmPassword}
                    </div>
                  )}
                </Form.Group>
              </Form>


            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={savePassword}


                style={{
                  width: '100%',
                  backgroundColor: '#E85C33',
                  borderColor: '#E85C33',
                }}
              >
                {translate('Save Password')}
              </Button>
            </Modal.Footer>
          </Modal>



          <Modal width={{ width: "10%" }} dialogClassName="modal-dialog-centered"
            centered show={passwordChangedSuccessfully} onHide={handleClosePasswordSavedModal} >
            <div className="my-4">
              <div className="text-center mt-5"><img src="save.png"></img></div>
              <div className="text-center mt-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>{translate('Success')}</div>
              <div className="text-center my-3">            {translate('Password Updated')}
                <br />{translate('Successfully')}</div>
            </div>
          </Modal>


          <Modal width={{ width: "10%" }} dialogClassName="modal-dialog-centered"
            centered show={passwordChangedSuccessfully} onHide={handleClosePasswordSavedModal} >
            <div className="my-4">
              <div className="text-center mt-5"><img src="save.png"></img></div>
              <div className="text-center mt-3 " style={{ fontSize: '20px', fontWeight: 'bold' }}>{translate('Success')}</div>
              <div className="text-center my-3">            {translate('Password Updated')}
                <br />{translate('Successfully')}</div>
            </div>
          </Modal>


          {/* <Modal dialogClassName="modal-dialog-centered"
        centered
        show={showConfirmLogoutModal}
        onHide={() => setShowConfirmLogoutModal(false)}

      >


        <div className="my-4">
          <div className="text-center my-3" style={{ color: '#6B6B6B' }}>Are You Sure, You want to Logout !</div>
          <Row>
            <Col xl={6} lg={6} md={6}><Button style={{ background: 'var(--layout-100, #F9F9F9)', color: 'black', border: 'unset', float: 'right' }} onClick={handleConfirmLogoutHide}>Cancel</Button></Col>
            <Col xl={6} lg={6} md={6}><Link to="/welcome"> <Button style={{ background: 'var(--buttons-red-700, #C84242)', color: 'white', border: 'unset' }} onClick={logout} >Logout</Button></Link></Col>
          </Row>
        </div>


      </Modal> */}
          <Modal dialogClassName="modal-dialog-centered"
            centered
            show={showConfirmLogoutModal}
            onHide={() => setShowConfirmLogoutModal(false)}

          >

            <div className="my-4">
              <div className="text-center my-3" style={{ color: '#6B6B6B' }}>{translate('Are You Sure, You want to Logout!')}</div>
              <Row>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}><Button style={{ background: 'var(--layout-100, #F9F9F9)', color: 'black', border: 'unset', float: 'right' }} onClick={handleConfirmLogoutHide}>{translate('Cancel')}</Button></Col>
                <Col xl={6} lg={6} md={6} sm={6} xs={6}><Link to="/welcome"> <Button style={{ background: 'var(--buttons-red-700, #C84242)', color: 'white', border: 'unset' }} onClick={logout} >{translate('Logout')}</Button></Link></Col>
              </Row>
            </div>


          </Modal>


        </Container>
      )}
    </div>
  );
}