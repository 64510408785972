import React, {useEffect, useState } from 'react';
import './custom.css';
import MyNavbar from './Navbar';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import axios from 'axios';
import { useNavigate} from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';



export default function MySecurity(){

  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }

    const [passwordVisible, setPasswordVisible] = useState(false);

    const [localStorageValue, setLocalStorageValue] = useState('');
    const [ invalidCredentails,valid ] = useState(true)
    const [ invalidValue,validcredentails ] = useState('')

    const [ codeValue,setValue ] = useState('')

    const [ verfiyValue , setVerifyValue ] = useState('')

    const checkValue = (e) =>{
      setValue(e.target.value.replace(/[^0-9]/g, "").slice(0, 6))
      setVerifyValue('');
      valid(true)
      validcredentails('');
      if(codeValue.length >= 6 ){
          return
      }
      // setValue(e.target.value);
     
    }
    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };

    useEffect(() => {
      const keyExists = localStorage.getItem('hnhpapaemail') !== null;
      if (keyExists) {
        const storedValue = localStorage.getItem('hnhpapaemail');
        setLocalStorageValue(storedValue);
      } else {
      }
    }, []);

    let navigate=useNavigate()
    const emailVerify = async () => {
      
      if(codeValue=="")
      {
        valid(false)
        validcredentails(`${translate('Please Enter Security Code')} `);

      }else{
     
              
              let response = await axios({
                    method : 'post',
                    url : `${Base_Url}/passwordotpverify`,
                    data : {
                      ParentEmail : localStorageValue,
                      ParentPasswordOTP : codeValue,
                    }
                })
                .then((res)=>{
                    console.log('response',res);
                    if(res.data.status == true ){
                      validcredentails('')
                      navigate('/Newpw')
                    }else{
                      valid(false)
                      validcredentails(res.data.message)
                    }
                }).catch((error)=>{
                  console.error('Error',error)
                })
      }
  
            
          
  }
  
    return (
      <>
          <MyNavbar />
          <Container
        className="d-flex align-items-center vh-100"
        fluid
      >
          <Container className="">
            <Row className="justify-content-center ">
              <Col md={6} lg={4} className="justify-content-center align-items-center ">
              <div style={{ borderRadius: '10px', backgroundColor: '#EFEFEF' }}className=" border border-gray-100 p-4 rounded mb-5">
                <Form>
                <Link to="/pwreset">  <img src="leftarrow.png" className="pb-3"/></Link>

                  <div style={{ fontSize: '18px' }} className="fw-bold">
                     {translate('Enter your Security Code')}

                  </div>
                  <div style={{ fontSize: '15px',fontWeight:'500px' }} className=" text-muted mb-4 mt-4">
                  {translate('We sent a six-digit security code to')}*****bh**@gmail.com

                  </div>
                  <Form.Control
                    className={(invalidCredentails ? false : 'errorField')}
                    style={{ padding: '10px', borderRadius: '9px' }}
                    type="email"
                    placeholder={translate('Enter the security code')}
                    onChange={(e)=>checkValue(e)}
                    value={codeValue}
                  />
                 
                  {
                    invalidCredentails  ? false : <p style={{color:'red',fontSize:'12px'}}>{invalidValue}</p> 
                  }
  
  
                  <Button
                    className="fw-bold mt-5  text-white"
                    style={{
                      width: '100%',
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      color: 'white',
                      padding: '10px',
                      borderRadius: '9px',
                    }}
                    onClick={emailVerify}
                  >
                                         {translate('Continue')}

                  </Button>
                
                </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    );
};


