import React, { useEffect, useState } from 'react'
import './custom.css'
// import 'react-calendar/dist/Calendar.css'; 
// import Calendar from 'react-calendar'


import { Container, Modal } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import MyNavbar from './Navbar';
import { ListGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from './LanguageContext';
import { Base_Url } from './globalvariable/globe';
import axios from 'axios';
import { useParams } from 'react-router-dom';




export default function MyCalendar() {
  const boxStyle = {
    borderRadius: '5px',

  }
  const boxStyle1 = {
    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  }

  const remove = {
    color: '#E85C33',
    fontSize: '15px'


  }
  const schoolName = {
    fontSize: "15px"

  }
  const placeColor = {
    fontSize: "15px"

  }
  const total = {
    color: '#E85C33',
    fontSize: '17px'

  }
  const order = {
    color: '#303A5B',
    fontSize: '15px',
    fontWeight: 'bold'
  }
  const subtotal = {
    color: '#7C7C7C'

  }
  const check = {
    color: '#303A5B',


  }
  const { translate, changeLanguage } = useTranslation();


  const { childrenId, id } = useParams();
  console.log(childrenId, 'children Id')
  console.log(id, 'id Id')
  // Calender section
  const [currentDate, setCurrentDate] = useState(new Date());
  const [calendarData, setCalendarData] = useState('');
  const [menuList, setMenuList] = useState([]);
  const currentDate1 = new Date();
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1)

  const year = currentDate1.getFullYear();
  const month = String(currentDate1.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(currentDate1.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;
  const formatedViewDate = `${year}-${month}`;
  const [currDay, setCurrDay] = useState(day)
  const [showMenuInfo, setShowMenuInfo] = useState(false)
  const [infoInd, setInfoInd] = useState(0)

  let currentDateMonth;
  let currentDates;
  let currentDateYear;

  const handleDayClick = (day, month, year) => {
    let day1, month1, data;
    if (day < 10) {
      day1 = '0' + day;
    } else {
      day1 = day;
    }
    if (month < 10) {
      month1 = '0' + month;
    } else {
      month1 = month;
    }
    console.log('Clicked day:', year + '-' + month1 + '-' + day1);
    data = year + '-' + month1 + '-' + day1;
    calendarMenu(data);
    // setCurrDate(data);
    setSelectedMonth(month1)
    setCurrDay(day)
  };

  // const getMenuValuesByGroupId = (groupId) => {
  //   const group = menuList.find(item,ind => ind == groupId);

  //   return group ? group.menuvalues : [];
  // };
  const { selectedLanguage } = useTranslation();

  const [modalDesciption, setModalDescription] = useState('');
  const [modalRemark, setModalRemark] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [showModal, setShowModal] = useState(false);


  const viewModal = (index) => {
    // const menuValuesForGroupId1 = getMenuValuesByGroupId(index);
    let title, description, remark, notes;

    if (selectedLanguage === 'en') {
      title = menuList[index].MenuTittleEnglish;
      description = menuList[index].MenuDescriptionEnglish;
      remark = menuList[index].MenuRemarksEnglishTop;
      notes = menuList[index].MenuRemarksEnglishBottom;
    } else if (selectedLanguage === 'es') {
      title = menuList[index].MenuTittleChinese;
      description = menuList[index].MenuDescriptionChinese;
      remark = menuList[index].MenuRemarksEnglishTop;
      notes = menuList[index].MenuRemarksEnglishBottom;
    }

    setModalDescription(description);
    setModalRemark(remark);
    setModalTitle(title);
    setNotes(notes)
    setShowModal(true);
  }


  const calendarMenu = async (datetime) => {
    let bodyFormData = new FormData()
    bodyFormData.append('ChildrenID', childrenId)
    bodyFormData.append('ScanDate', datetime)
    bodyFormData.append('orderID', id)

    let responce = await axios({
      method: 'post',
      url: Base_Url + '/viewfilterscans',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setMenuList(res.data.result)
        } else {
          setMenuList([])
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });

  }

  const generateCalendarDays = () => {
    const dot1Color = 'green';
    const dot2Color = 'gray';


    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const days = [];
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const offset = firstDayOfMonth.getDay();

    for (let i = 0; i < offset; i++) {
      days.push(
        <div key={`empty-${i}`} className="he calendar-day empty"></div>
      );
    }

    for (let i = 1; i <= daysInMonth; i++) {
      const dayDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), i);
      const isSunday = dayDate.getDay() === 0;
      days.push(
        <div
          key={i}
          id={`day-${i}`}
          className={`calendar-day ${isSunday ? 'sunday' : ''}`}
          onClick={() => handleDayClick(i, currentDate.getMonth() + 1, currentDate.getFullYear())}
        >
          <div className={`${currDay == i && currentDate.getMonth() + 1 == selectedMonth && currentDate.getFullYear() == year ? 'selected-day' : ''}`}>
            {i}
          </div>
          <div style={{ display: 'none' }}> {currentDateYear = currentDate.getFullYear()}</div>
          {(currentDate.getMonth() + 1) > 9 ? (
            <div style={{ display: 'none' }}>
              {currentDateMonth = currentDate.getMonth() + 1}

            </div>
          ) : (
            <div style={{ display: 'none' }}>
              {currentDateMonth = '0' + (currentDate.getMonth() + 1)}

            </div>
          )}
          {i > 9 ? (
            <div style={{ display: 'none' }}>
              {currentDates = i}

            </div>
          ) : (
            <div style={{ display: 'none' }}>
              {currentDates = '0' + i}

            </div>
          )}
          <div>
            {
              calendarData[currentDateYear + '-' + currentDateMonth + '-' + currentDates] && calendarData[currentDateYear + '-' + currentDateMonth + '-' + currentDates].length > 0 ?
                (calendarData[currentDateYear + '-' + currentDateMonth + '-' + currentDates].map((item, index) => (
                  <>

                    {item.ScanStatus == 'redeemed' ? (

                      <div className="dot ms-3" style={{ background: dot1Color }}></div>


                    ) : (

                      <div className="dot ms-3 " style={{ background: dot2Color }}></div>

                    )}
                  </>
                ))
                ) : (
                  <></>
                )}</div>

        </div>
      );
    }

    return days;
  };

  const handlePrevMonth = () => {
    const prevMonth = new Date(currentDate);
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    setCurrentDate(prevMonth);
    const dateObject = new Date(prevMonth);

    const year12 = dateObject.getFullYear();
    const month12 = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const formattedDate12 = `${year12}-${month12}`;
    calendarList(id, formattedDate12);
  };

  const handleNextMonth = () => {
    const nextMonth = new Date(currentDate);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    setCurrentDate(nextMonth);
    const dateObject = new Date(nextMonth);

    const year12 = dateObject.getFullYear();
    const month12 = String(dateObject.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const formattedDate12 = `${year12}-${month12}`;
    calendarList(id, formattedDate12);

  };

  const calendarList = async (id, year) => {
    let bodyFormData = new FormData()
    bodyFormData.append('ScanMonth', year)
    bodyFormData.append('ChildrenID', childrenId)
    bodyFormData.append('orderID', id)

    let responce = await axios({
      method: 'post',
      url: Base_Url + '/viewfiltercalender',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          setCalendarData(res.data.result)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });

  }
  useEffect(() => {
    calendarMenu(formattedDate);
    calendarList(id, formatedViewDate);
  }, [])
  return (
    <>

      <Container>
        <MyNavbar />
        <div className="my-4">

          <Row className=" ">
            <Col xs={5} sm={7} md={5} lg={3} xl={3}>
              <Link to="/history" className="link-no-underline">
                <img src={process.env.PUBLIC_URL + '/leftarrow.png'} alt="School Logo" className="pe-1" />Calendar
              </Link>
            </Col>
            <Col xs={7} sm={5} md={7} lg={9} xl={9} className="text-end">
              <Link to={`/payment/${id}`}><img src={process.env.PUBLIC_URL + '/document.png'}></img></Link>
            </Col>

          </Row>
        </div>
        {/* <Col  xs={5} sm={7} md={5} lg={3} xl={3} className="mt-4">
            <Link  to="/children" className="link-no-underline ">
          <img src="leftarrow.png" alt="School Logo"className="pe-2 " />Calendar
          </Link>
        </Col> */}

        <Row className="my-5">


          <Col md={8}>


            {menuList && menuList.length > 0 ? (
              menuList.map((item, index) => (
                <Row className="mx-1 mt-3" style={{ background: 'var(--layout-100, #F9F9F9)' }}>
                  {console.log(item)}
                  <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                    <Row className="mt-1 mb-3">
                      <Col lg={6} xl={8} md={6} xs={4} sm={4}>

                        <div className="text-start " style={{ color: '#303A5B', fontSize: '17px', fontWeight: 'bold' }}>
                          {selectedLanguage === 'en' && (
                            <>
                              {item.MenuTittleEnglish}

                            </>

                          )}
                          {selectedLanguage === 'es' && (
                            <>
                              {item.MenuTittleChinese}

                            </>

                          )}
                        </div>
                      </Col>

                      {/* <Col lg={6} xl={6} md={8} xs={8} sm={8}>

                        <div className="text-end" style={{ color: '#188A67', fontSize: '15px' }}>
                          <Button className=""
                            style={{
                              width: '30%', backgroundColor: item.ScanStatus == 'redeem' ? '#188A67' : '#E85C33',
                              borderColor: item.ScanStatus == 'redeem' ? '#188A67' : '#E85C33',
                              color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px'
                            }}>
                            {translate(item.ScanStatus)}
                          </Button>
                        </div>
                      </Col> */}
                      <Col lg={6} xl={4} md={6} xs={8} sm={8}>
                        <div className="text-end">
                          {item.ScanStatus == 'not-redeemed' ?
                            <Button className="" style={{ width: '60%', backgroundColor: '#E85C33', borderColor: '#E85C33', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px' }}>{translate(item.ScanStatus)}</Button>
                            :
                            <Button className="" style={{ width: '60%', backgroundColor: '#188A67', borderColor: '#188A67', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px' }}>{translate(item.ScanStatus)}</Button>
                          }
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} sm={12} md={12} lg={12} xl={10}>

                        <div style={{ fontSize: '15px', fontWeight: 'bold' }}>
                          {selectedLanguage === 'en' && (
                            <>
                              {item.MenuTypeEnglish}

                            </>

                          )}
                          {selectedLanguage === 'es' && (
                            <>
                              {item.MenuTypeChinese}

                            </>

                          )}

                          <img
                            onClick={() => viewModal(index)}
                            className=" ms-1" src={process.env.PUBLIC_URL + '/info_Icon.png'} alt="info"
                            style={{ width: '18px', height: '18px', }}>

                          </img>
                        </div>

                        {/* <ul style={{fontSize:'15px'}}>
<li>Scrambled Egg with Whole Wheat Toast</li>
<li>Fresh Orange Slices</li>
<li>Milk or Fruit Juice</li>
</ul> */}
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={2} className="d-flex flex-column align-items-end justify-content-end">

                        {item.ScanTime ? <div className="text-muted">@{item.ScanTime}</div> : null}
                      </Col>
                    </Row>
                  </div>

                </Row>
              ))
            ) : (
              // Render some placeholder or loading UI when scanData is not available yet
              <div style={boxStyle1} className="border border-gray-100 my-1 w-25 py-2 px-3">
                <p style={{ color: '#E85C33' }}>{translate('No Data Found')}</p>
              </div>
            )}

          </Col>


          {/* Calendar Column */}
          <Col md={4}>
            <Row className="calendar-header">
              <Col className="text-center">
                {/* <Row className="calendar-header">
              <Col className="text-center">
                <Button variant="link" onClick={handlePrevMonth} className="arrow-button">
                  <img src={process.env.PUBLIC_URL + '/prevbtn.png'} />
                </Button>
                {currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}
                <Button variant="link" onClick={handleNextMonth} className="arrow-button">
                  <img src={process.env.PUBLIC_URL + '/nextbtn.png'} />
                </Button>
              </Col>
            </Row> */}
                <Row>
                  <Col lg={2} xl={2} md={2} sm={2} xs={2}>
                    <Button variant="link" onClick={handlePrevMonth} className="arrow-button">
                      <img src={process.env.PUBLIC_URL + '/prevbtn.png'} />
                    </Button>
                  </Col>

                  <Col lg={8} xl={8} md={8} sm={8} xs={8} >
                    {currentDate.toLocaleString('default', { month: 'long' })}
                    <div className="pb-2" style={{ color: '#8F9BB3', fontSize: '17px', fontWeight: '500' }}>    {currentDate.getFullYear()}</div>

                  </Col>

                  <Col lg={2} xl={2} md={2} sm={2} xs={2}>
                    <Button variant="link" onClick={handleNextMonth} className="arrow-button">
                      <img src={process.env.PUBLIC_URL + '/nextbtn.png'} className="" />
                    </Button>
                  </Col>

                </Row>
              </Col>
            </Row>
            <Row className="calendar-days" style={{ color: '#8F9BB3' }}>
              {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map((day, index) => (
                <Col key={index} className={`text-center pe-0 me-0 day-label ${day === 'Sun' ? 'sunday' : ''}`} >
                  {day}

                </Col>
              ))}
            </Row>
            <Row className="calendar-grid">
              {generateCalendarDays()}
            </Row>

          </Col>
        </Row>





        <Modal show={showModal} onHide={() => setShowModal(false)} dialogClassName="modal-dialog-centered"
          centered>
          <Modal.Header closeButton>
            <Modal.Title className="" style={{ fontSize: '17px' }} dangerouslySetInnerHTML={{ __html: modalTitle }}></Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >

            <div dangerouslySetInnerHTML={{ __html: modalRemark }} />
            <hr style={{ borderColor: '#ccc' }} />
            <div dangerouslySetInnerHTML={{ __html: modalDesciption }} />
            <hr style={{ borderColor: '#ccc' }} />
            <div dangerouslySetInnerHTML={{ __html: notes }} />

            <Row>

            </Row>


          </Modal.Body>

        </Modal>


      </Container>


    </>
  );

}