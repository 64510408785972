import React, { useState } from 'react';
import './custom.css';
import MyNavbar from './Navbar';
import NavbarLogin from './Navbarlogin';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loaderbtn from './Loaderbtn';
import Spinner from 'react-bootstrap/Spinner';


export default function MyWelcome() {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { translate, changeLanguage } = useTranslation();
  const [button, SetButton] = useState(false);

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const successToast = (e) => {
    toast.dismiss();
    toast.success(e);
  };
  const errorToast = (e) => {
    toast.dismiss();
    toast.error(e);
  };

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [verfiyEmail, setVerifyEmail] = useState(true)
  const [verfiyPassword, setVerifyPasswword] = useState(true)
  const [invalidCredentails, valid] = useState(true)


  const checkEmail = (e) => {
    setEmail(e.target.value);
    setVerifyEmail(true);
    valid(true)
  }

  const checkPassword = (e) => {
    setPassword(e.target.value);
    setVerifyPasswword(true);
    valid(true)
  }
  let navigate = useNavigate()
  const signin = async () => {

    if (password == "") {
      setVerifyPasswword(false)
    }
    if (email == "") {
      setVerifyEmail(false)

    } else if (email != "") {

      let emailveri = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(emailveri)) {

        if (password == "") {
          setVerifyPasswword(false);
        } else {
          SetButton(true)
          let response = await axios({
            method: 'post',
            url: `${Base_Url}/login`,
            data: {
              ParentEmail: email,
              ParentPassword: password
            }
          })
            .then((res) => {
              console.log('response', res);
              if (res.data.status == true) {
                SetButton(false)
                localStorage.setItem('hnhpapaemail', email);
                if (res.data.otpverify == 1) {
                  localStorage.setItem('hnhpapauserId', res.data.result.user.ParentID);
                  localStorage.setItem('hnhpapaSchoolId', res.data.result.user.ParentSchoolID);
                  localStorage.setItem('ParentLanguage', res.data.result.user.ParentLanguage);

                  // successToast('Login Success');
                  // setTimeout(() => {
                  navigate('/')
                  // }, 1000);

                } else {

                  errorToast('Otp Not Verified');
                  setTimeout(() => {
                    navigate('/otpform?key=login')
                  }, 1000);

                }
              } else {
                errorToast(res.data.message);
                SetButton(false)
                valid(false)
              }
            }).catch((error) => {
              console.error('Error', error)
              SetButton(false)
            })
        }

      } else {

        setVerifyEmail(false)
      }

    } else {

    }

  }

  return (

    <>
      <ToastContainer />
      <NavbarLogin />
      <Container
        className="d-flex align-items-center vh-100"
        fluid
      >

        <Container>
          <Row className="justify-content-center">
            <Col md={6} lg={4}>
              <div style={{ borderRadius: '10px', backgroundColor: '#EFEFEF' }} className=" border border-gray-100 p-4 rounded mb-5">
                <Form className="">
                  <div style={{ fontSize: '18px' }} className="fw-bold ">
                    {translate('Welcome Back')}!

                  </div>
                  <div style={{ fontSize: '18px' }} className="fw-bold mb-4">
                    {translate('Glad to see you Again')}!

                  </div>
                  <form >
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label style={{ fontSize: '16px' }}>{translate('Email')}</Form.Label>
                      <Form.Control
                        className={(verfiyEmail ? false : 'errorField')}
                        style={{ padding: '10px', borderRadius: '9px' }}
                        type="email"
                        placeholder=
                        {translate('Enter Email')}
                        onChange={(e) => checkEmail(e)}
                        value={email}
                      />
                      {
                        verfiyEmail ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Valid Email')}</p>
                      }
                    </Form.Group>

                    <Form.Group controlId="formBasicPassword" style={{ marginTop: '20px' }}>
                      <Form.Label style={{ fontSize: '16px' }}>{translate('Password')}</Form.Label>
                      <div style={{ position: 'relative' }}>
                        <Form.Control
                          className={(verfiyPassword ? false : 'errorField')}
                          type={passwordVisible ? 'text' : 'password'}
                          placeholder={translate('Enter Password')}
                          style={{ padding: '10px', borderRadius: '9px' }}
                          onChange={(e) => checkPassword(e)}
                          value={password}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            right: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={togglePasswordVisibility}

                        >
                          {passwordVisible ? (

                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16" style={{ marginRight: '3px' }}>
                              <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                              <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                            </svg>
                          ) : (
                            <img src="eye.png" alt="Hide Password" />

                          )}
                        </div>
                      </div>
                      {
                        verfiyPassword ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>Enter Password</p>
                      }
                    </Form.Group>



                    <div className=" text-end fw-bold text-muted mt-3">
                      <Link to="/pwreset" className="link-no-underline">
                        {translate('Forgot Password')}?
                      </Link>
                    </div>

                    {button == true ? (
                      <Button
                        className="fw-bold mt-4  text-white"
                        style={{
                          width: '100%',
                          backgroundColor: 'black',
                          borderColor: 'black',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '9px',
                        }}
                      >
                        <Spinner animation="border" role="status">
                        </Spinner>

                      </Button>
                    ) : (
                      <Button
                        className="fw-bold mt-4  text-white"
                        style={{
                          width: '100%',
                          backgroundColor: 'black',
                          borderColor: 'black',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '9px',
                        }}
                        onClick={signin} >

                        {translate('Login')}
                      </Button>
                    )}

                  </form>
                  <div className="text-center mt-4">

                    {translate('Dont have an account')}?
                    <Link to="/register" className="link-no-underline">
                      <span style={{ color: '#E85C33' }}>
                        {translate('Register Now')}
                      </span>
                    </Link>
                    {
                      invalidCredentails ? false : <p style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>Invalid Email / Password</p>
                    }
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
