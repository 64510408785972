import React, { useEffect, useState } from 'react'
import './custom.css'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import MyNavbar from './Navbar';
import { ListGroup } from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import { Dropdown } from 'react-bootstrap';
import { Toast } from 'react-bootstrap';
import { useTranslation } from './LanguageContext';
import Loader from './Loader';
import DatePicker from 'react-datepicker';
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';



export default function MyNeworder() {
  const wordLimit = 6
  const { translate, changeLanguage } = useTranslation();
  const { selectedLanguage } = useTranslation();
  const [loading, setLoading] = useState(false)

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [parentId, setLocalStorageParentId] = useState('');
  const [schoolId, setLocalStorageSchoolId] = useState('');
  const [menuLists, setLocalStorageMenuList] = useState([]);
  const [childrenList, setChildren] = useState([]);
  const [children, setChildren1] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [firstData, firstData1] = useState('');
  const [menuArray, setMenuArray] = useState([]);
  const [modalValue, setModalValue] = useState('');
  const [modalDesciption, setModalDescription] = useState('');
  const [modalRemark, setModalRemark] = useState('');
  const [menuImages, setMenuImages] = useState(null);

  const [childrenIndex, setChildrenIndex] = useState(0);
  const [showToast, setShowToast] = useState(false);
  const [showToast1, setShowToast1] = useState(false);
  const [showToastone, setShowToastone] = useState(false);
  const keyExists = localStorage.getItem('hnhpapacartCount') !== null;
  const [cartCount, setcartCount] = useState(keyExists ? localStorage.getItem('hnhpapacartCount') : 0);
  const [menuOrderedId, setMenuOrderedId] = useState([])
  const [modalTitle, setModalTitle] = useState('');

  const [currMnth, setCurrMnth] = useState(true)

  const [checkCurrMnth, setCheckCurrMnth] = useState([])
  const [checkNextMnth, setCheckNextMnth] = useState([])

  // const dateFromObject = new Date(fromDate);
  // const dateToObject = new Date(todate);
  // const toMonth = (dateToObject.getMonth() + 1).toString().padStart(2, '0');
  const [fromDays, setFromDays] = useState([])
  const [toDays, setToDays] = useState([])
  const [selectedDate, setSelectedDate] = useState(new Date());
  const dateFromObject = new Date(selectedDate);
  const month = (dateFromObject.getMonth() + 1).toString().padStart(2, '0');
  const year = dateFromObject.getFullYear();
  const intValue = parseInt(month, 10);
  const cartMonthYear = `${year}-${month}`;
  // const cartMonthYear = year+'-'+month;

  const [cartType, setCartType] = useState('month')
  const [prodDays, setProdDays] = useState([])
  const [showAddPrdctModel, setShowAddprdctModel] = useState(false)

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const [menuId, setMenuId] = useState(0)
  // const [currParentId, setCurrParentId] = useState(0)
  // const [currSclId, setCurrSclId] = useState(0)
  const [currChldrnId, setCurrChldrnId] = useState(0)
  const [noChld, setNoChild] = useState(false)

  const [isProceed, setIsproceed] = useState(false)
  const [menuDetails, setMenuDetails] = useState(null)
  const [showMenuDtlModal, setShowMenuDtlModal] = useState(false)

  console.log(menuDetails, "det")

  // Calculate the last day of the current month
  const lastDayOfCurrentMonth = new Date(currentYear, currentMonth + 1);
  const firstDayOfNextMonth = new Date(currentYear, currentMonth + 1, 1);

  // Set minDate to the first day of the current month
  // Set maxDate to the last day of the current month
  // const currentMonthHasMenu = checkCurrMnth.includes(menuId);
  // const nextMonthHasMenu = checkNextMnth.includes(menuId);

  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()
  const options = { month: 'long' };
  const minMonth = minDate?.toLocaleDateString(undefined, options);
  const maxMonth = maxDate?.toLocaleDateString(undefined, options);

  const availableDates = [minMonth, maxMonth]

  const checkMinMaxDay = () => {
    if (checkCurrMnth.includes(menuId)) {
      // MenuId is in current month, so only display current month
      setMinDate(lastDayOfCurrentMonth);
      setSelectedDate(lastDayOfCurrentMonth)
      // setMaxDate(lastDayOfCurrentMonth);
    }
    else {
      // MenuId is not in current or next month, display both months
      setMinDate(new Date(currentYear, currentMonth, 1));
      setSelectedDate(new Date(currentYear, currentMonth, 1))
      // setMaxDate(new Date(currentYear, currentMonth + 1, 0));

    }
    if (checkNextMnth.includes(menuId)) {
      // MenuId is in next month, so only display next month
      // setMinDate(firstDayOfNextMonth);
      setMaxDate(new Date(currentYear, currentMonth + 1, 0));
      // setMaxDate(firstDayOfNextMonth);
    }
    else {
      // MenuId is not in current or next month, display both months
      // setMinDate(new Date(currentYear, currentMonth, 1));
      // setMaxDate(new Date(currentYear, currentMonth + 1, 0));
      setMaxDate(firstDayOfNextMonth);

    }
  }



  const [notes, setNotes] = useState('');
  const [allPrdctDays, setAllPrdctDays] = useState([])

  const boxStyle = {
    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px',
    //marginLeft: '10px'
  }
  const remove = {
    color: '#E85C33',
    fontSize: '15px'


  }
  const schoolName = {
    fontSize: "15px"

  }
  const placeColor = {
    fontSize: "15px"

  }
  const total = {
    color: '#E85C33',
    fontSize: '17px'

  }
  const order = {
    color: '#303A5B',
    fontSize: '15px',
    fontWeight: 'bold'
  }
  const subtotal = {
    color: '#7C7C7C'

  }
  const check = {
    color: '#303A5B',


  }

  const content = {
    fontSize: "13px"

  }
  const dayNamesMapping = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };

  const sortedAllPrdctDays = allPrdctDays.sort((a, b) => {

    const daysOrder = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return daysOrder.indexOf(a.day) - daysOrder.indexOf(b.day);
  });

  const handleAddToCart = () => {
    setShowToast(true);

    // Hide the toast after a certain delay (e.g., 3 seconds)
    setTimeout(() => {
      setShowToast(false);
    }, 3000);
  };
  const handleAddToCart1 = () => {
    setShowToast1(true);

    // Hide the toast after a certain delay (e.g., 3 seconds)
    setTimeout(() => {
      setShowToast1(false);
    }, 3000);
  };
  const handleAddToCartone = () => {
    setShowToastone(true);

    // Hide the toast after a certain delay (e.g., 3 seconds)
    setTimeout(() => {
      setShowToastone(false);
    }, 3000);
  };

  // handleAddToCartone()
  let navigate = useNavigate()

  const getDetails = () => {
    setLoading(true);
    const keyExists = localStorage.getItem('hnhpapauserId') !== null;
    let storedValue;
    let storedValues;
    if (keyExists) {
      storedValue = localStorage.getItem('hnhpapauserId');
      setLocalStorageParentId(storedValue);
    } else {
      navigate('/welcome');
    }
    const keyExistsSchool = localStorage.getItem('hnhpapaSchoolId') !== null;
    if (keyExistsSchool) {
      storedValues = localStorage.getItem('hnhpapaSchoolId');
      setLocalStorageSchoolId(storedValues);
    } else {
      navigate('/welcome');
    }

    menuList(storedValue, storedValues)
  }

  const menuList = async (storedValue, storedValues) => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/menulist`,
      data: {
        ParentID: storedValue,
        ParentSchoolID: storedValues,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setLocalStorageMenuList(res.data.result.menuListByCategory)
          setChildren(res.data.result.children)
          setApiData(res.data.result);
          firstData1(res.data.result.children[0]);
          setCheckCurrMnth(res.data.result.children[0].currentmonth)
          setCheckNextMnth(res.data.result.children[0].nextmonth)
          setChildrenIndex(0);
          setMenuArray(res.data.result.children[0].CartMenuID);
          setMenuOrderedId(res.data.result.children[0].CartMenuOrderedID);
          setNoChild(false)
          setLoading(false)

        } else {
          setLoading(false)
          if (res.data) {
            setNoChild(true)

          }

        }
      }).catch((error) => {
        console.error('Error', error)
        // setLoading(false)

      })
  }
  const menuListChildren = async (parentId, schoolId, index) => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/menulist`,
      data: {
        ParentID: parentId,
        ParentSchoolID: schoolId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setLocalStorageMenuList(res.data.result.menuListByCategory)
          setChildren(res.data.result.children)
          setApiData(res.data.result);
          firstData1(res.data.result.children[index]);
          setChildrenIndex(index);
          setMenuArray(res.data.result.children[index].CartMenuID);
          setMenuOrderedId(res.data.result.children[index].CartMenuOrderedID);
          setCheckCurrMnth(res.data.result.children[index].currentmonth)
          setCheckNextMnth(res.data.result.children[index].nextmonth)
          setLoading(false)

        } else {
          setLoading(false)

        }
      }).catch((error) => {
        console.error('Error', error)
        // setLoading(false)

      })
  }

  const getMenuDetail = async (menuId) => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/getmenudetails`,
      data: {
        menuId: menuId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setMenuDetails(res.data.data.menuDetails);
          setLoading(false);
          // console.log(res.data,"det inn")
        }
        //  else {
        //   setLoading(false)
        // }
      }).catch((error) => {
        console.error('Error', error)
        // setLoading(false)

      })
  }

  useEffect(() => {
    getDetails();
  }, [])

  useEffect(() => {
    checkMinMaxDay();
  }, [menuId])

  // useEffect(() => {
  //   if (menuId > 0) {
  //     cnfrmAddCart(menuId)

  //   }
  // }, [selectedDate])

  useEffect(() => {
    if (isProceed) {
      addCart(menuId, parentId, schoolId, currChldrnId)
      setProdDays([])

    }
  }, [isProceed])

  const appendChild = (index) => {

    menuListChildren(parentId, schoolId, index)
    firstData1(childrenList[index]);
    setMenuArray(childrenList[index].CartMenuID);
    setShowSelectChildModal(false)
  }
  const getMenuValuesByGroupId = (groupId) => {
    const group = menuLists.find(item => item.groupid === groupId);

    return group ? group.menuvalues : [];
  };

  // Get menuvalues for groupid 1

  const viewModal = (index, id) => {
    const menuValuesForGroupId1 = getMenuValuesByGroupId(id);
    let title, description, remark, notes;

    if (selectedLanguage === 'en') {
      title = menuValuesForGroupId1[index].MenuTittleEnglish;
      description = menuValuesForGroupId1[index].MenuDescriptionEnglish;
      remark = menuValuesForGroupId1[index].MenuRemarksEnglish;
      notes = menuValuesForGroupId1[index].notesenglish;
    } else if (selectedLanguage === 'es') {
      title = menuValuesForGroupId1[index].MenuTittleChinese;
      description = menuValuesForGroupId1[index].MenuDescriptionChinese;
      remark = menuValuesForGroupId1[index].MenuRemarksChinese;
      notes = menuValuesForGroupId1[index].noteschinese;
    }
    let menuImgs = menuValuesForGroupId1[index].menuImages

    setMenuImages(menuImgs)
    setModalDescription(description);
    setModalRemark(remark);
    setModalTitle(title);
    setNotes(notes)
    setShowModal(true);
  }

  const addCart = async (menuId, parentid, schoolid, childrenId) => {
    setShowAddprdctModel(false)

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/addcart`,
      data: {
        ParentID: parentid,
        ParentSchoolID: schoolid,
        ChildrenID: childrenId,
        MenuID: menuId,
        // CartType: cartType,
        // CartMonthYear: cartMonthYear,
        // CartDays: prodDays,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          // alert(res.data.message);
          handleAddToCart()
          menuListChildren(parentid, schoolid, childrenIndex)
          getcartCount()
          setIsproceed(false)

        } else {
          alert(res.data.message);

          setIsproceed(false)

        }
      }).catch((error) => {
        console.error('Error', error)
        setLoading(false)
        setIsproceed(false)


      })
  }
  //to get datwise api
  // const cnfrmAddCart = async (menuId) => {
  //   setShowAddprdctModel(true)
  //   const reqDates = {
  //     MenuID: menuId,
  //     year: year,
  //     month: intValue
  //   }
  //   let res = await axios.post(`${Base_Url}/DaywiseDatesApp`, reqDates)
  //     .then((response) => {
  //       setAllPrdctDays(response.data.result)
  //       setLoading(false)
  //     })
  //     .catch((error) => {
  //       setLoading(false)
  //       console.error(error);
  //     });



  // }

  const CustomIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <path fill="#E85C33" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 15h2v2h-2zm0-10h2v8h-2z" />
    </svg>
  );
  const addCart1 = () => {

    // alert('This Menu Already Added');
    // toast.error('This Menu Already Added');
    const errMsg = translate('This menu has already added!')
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: false,
      closeButton: true,
      icon: <CustomIcon />, // Custom icon component for the toast
    });
  }
  const getcartCount = async () => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/cartcount`,
      data: {
        ParentID: parentId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setcartCount(localStorage.setItem('hnhpapacartCount', res.data.result.cartItemCount));
        } else {
          alert(res.data.message);

        }
      }).catch((error) => {
        console.error('Error', error)
        setLoading(false)

      })
  }
  const deleteCart = async (childrenId, menuId) => {

    let response = await axios({
      method: 'post',
      url: `${Base_Url}/deletechildcart`,
      data: {
        ChildrenID: childrenId,
        MenuID: menuId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          // alert(res.data.message);
          getcartCount()
          handleAddToCart1()
          menuListChildren(parentId, schoolId, childrenIndex)

        } else {
          alert(res.data.message);

        }
      }).catch((error) => {
        console.error('Error', error)
        setLoading(false)

      })
  }






  const menuItem = {
    MenuDescriptionEnglish: '<div style={content}><ul><li>Your HTML content here.</li></ul></div>'
  };

  // const parsedHTML = ReactHtmlParser(menuItem.MenuDescriptionEnglish);
  const parsedHTML = "";



  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

  const [selectedMonth, setSelectedMonth] = useState('');

  const handleMonthSelect = (month) => {
    setSelectedMonth(month);
  };




  const orderDataimage = [
    {
      id: 1,
      imageSrc: 'food.jpg',
    },
    {
      id: 2,
      imageSrc: 'food.jpg',
    },
    {
      id: 3,
      imageSrc: 'food.jpg',
    },
    {
      id: 4,
      imageSrc: 'food.jpg',
    },
    {
      id: 5,
      imageSrc: 'food.jpg',
    },
    {
      id: 6,
      imageSrc: 'food.jpg',
    },
    {
      id: 7,
      imageSrc: 'food.jpg',
    },
    {
      id: 8,
      imageSrc: 'food.jpg',
    },
    {
      id: 9,
      imageSrc: 'food.jpg',
    },
  ];

  const renderMenu = (menuData) => {
    return menuData.map((item) => (
      <div key={item.week}>
        <div style={{ fontSize: '15px', fontWeight: 'bold' }}>{item.week}</div>
        <ul style={{ fontSize: '15px' }}>
          <li>{item.menuone}</li>
          <li>{item.menutwo}</li>
          <li>{item.menuthree}</li>
          <li>{item.menufour}</li>
        </ul>
      </div>
    ));
  }



  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModal = () => {
    setShowModal(true);
  };




  const orderDataone = [
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 350'
    },
    {
      title: 'Veg Premium',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 250'
    },
    {
      title: 'Non-Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 180'
    },
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 100'
    },
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 100'
    },


  ];


  const orderDatatwo = [
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 90'
    },
    {
      title: 'Non-Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 80'
    },
    {
      title: 'Veg Premium',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 180'
    },
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 100'
    },

  ];




  const orderDatathree = [
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 90'
    },
    {
      title: 'Non-Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 80'
    },
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 180'
    },
    {
      title: 'Veg Standard',
      imageSrc: 'mark.png',
      description: 'Standard Veg combo',
      price: 'RM 100'
    },

  ];

  const [expandedSections, setExpandedSections] = useState({});

  const toggleSection = (index) => {
    setExpandedSections((prevExpandedSections) => ({
      ...prevExpandedSections,
      [index]: !prevExpandedSections[index],
    }));
  };
  const [showSelectChildModal, setShowSelectChildModal] = useState(false);
  const handleSelectChild = () => {
    setShowSelectChildModal(true);
  }
  /*const getFirstFourLines = (html, characterLimit) => {
    const regex = /<p>(.*?)<\/p>/;
    const match = html.match(regex);
    

    if (match) {
      const lines = match[1].split('<br>');
      const firstTwoLinesHtml = lines.slice(0, 4).join('<br>');
      const truncatedHtml = firstTwoLinesHtml.slice(0, characterLimit);
      return { __html: truncatedHtml };
    }

    return { __html: '' }; // Return an empty string if no match is found
  };
  const characterLimit = 60;*/


  /*const getFirstWords = (html, wordLimit) => {
    // Remove HTML tags and split the text into words
    const words = html.replace(/<\/?[^>]+(>|$)/g, '').split(' ');
  
    // Join the first 'wordLimit' words
    const firstWords = words.slice(0, wordLimit).join(' ');
  
    return firstWords;
  };*/

  //To set the days clicked in adding product
  const handleAddProduct = (val) => {

    const isValueExists = prodDays.includes(val);

    if (isValueExists) {
      setProdDays(prodDays.filter(item => item !== val));
    } else {
      setProdDays([...prodDays, val]);
    }
  };
  const getFirstWords = (html, wordLimit) => {
    const wordsWithTags = html.split(/(?:\/)([^#]+)(?=#|$)/);

    let count = 0;
    const truncatedHtmlArr = [];

    for (const word of wordsWithTags) {
      if (count + word.split(' ').length <= wordLimit) {
        truncatedHtmlArr.push(word);
        count += word.split(' ').length;
      } else {
        break;
      }
    }

    return truncatedHtmlArr.join('');
  };


  return (
    <div>
      {loading && menuLists.length == 0 ? (
        <Loader />
      ) : (
        <>
          <ToastContainer limit={1} />
          <Container>
            <MyNavbar />

            {/* <div dangerouslySetInnerHTML={{ __html: htmlContent }} /> */}

            <Col xs={5} sm={7} md={5} lg={3} xl={3} className="mt-4 pb-3">
              <Link to="/" className="link-no-underline ">
                <img src="leftarrow.png" alt="School Logo" className="pe-2 " style={{ fontWeight: '300' }} /> {translate('Orders')}
              </Link>
            </Col>

            <Row>
              {!loading && noChld ?
                <div style={boxStyle} className="border border-gray-100 my-1 w-25  ms-5 py-3 px-3">
                  {!loading && childrenList.length == 0 ? <p style={{ color: '#E85C33' }}>{translate('Add Child to make orders')}</p>
                    : null}
                </div>
                :
                <Col lg={4} xl={4} md={12} xs={12} sm={12} >
                  <div style={boxStyle} className="border border-gray-100 my-1 py-1 px-3">
                    <Row className="flex align-items-center  " onClick={handleSelectChild}>
                      <Col xs={2} sm={2} md={1} lg={3} xl={2} className="text-end">
                        <img src={Image_Url + firstData.ChildrenImage} className="text-end" alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />

                      </Col>
                      <Col xs={7} sm={7} md={8} lg={6} xl={7} className="text-start">
                        <div style={schoolName} className="font-weight-bold text-black ">

                          {firstData.ChildrenName}
                        </div>
                        <div style={placeColor} className="placecolor  text-muted  color-white ">{firstData.ChildrenGrade}</div>
                      </Col>
                      <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <div className="text-end"><img src="dropdownimg.png"></img></div>
                      </Col>
                    </Row>

                  </div>
                </Col>
              }



              <Col lg={8} xl={8} md={12} className="text-end">
                {/* <div className="text-end ">{translate('Month')}</div> */}
                {/* <Dropdown >
      <Dropdown.Toggle style={{backgroundColor:'white',color:'black',borderColor:'#E1E1E1',fontSize:'14px'}} className="" id="dropdown-basic">
        {selectedMonth || 'Select Month'}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {months.map((month, index) => (
          <Dropdown.Item key={index} onClick={() => handleMonthSelect(month)}>
            {month}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown> */}
              </Col>


            </Row>


            <div style={{ marginTop: '40px' }}></div>

            {menuLists && menuLists.length > 0 ? (menuLists.map((item, index) => (
              <>
                <div style={{ color: 'black' }} className="my-1">
                  {selectedLanguage === 'en' && (
                    item.group
                  )}
                  {selectedLanguage === 'es' && (
                    item.groupChinese
                  )}

                </div>
                <Row key={index}>
                  <div className="d-flex flex-wrap">

                    {item.menuvalues && item.menuvalues.length > 0 ? (item.menuvalues.map((itemvalue, index1) => (

                      // {item.menuvalues.map((itemvalue, index1) => (
                      //   <Col key={index} xl={3} lg={4} md={6} sm={12} xs={12} className="my-1">
                      //     <div style={boxStyle} className="d-flex flex-column border border-gray-100 py-2 px-3 h-100"> {/* Apply flex column styling */}
                      //       {/* ... Your existing content ... */}
                      //     </div>
                      //   </Col>
                      // ))}

                      <Col key={index1} xl={3} lg={4} md={6} sm={12} xs={12} className="my-1 px-2">
                        <div style={boxStyle} className="d-flex flex-column border border-gray-100 py-2 px-3 h-100">
                          {/* <Link to="" className="link-no-underline"> */}
                          <Row className="flex align-items-center">
                            {selectedLanguage === 'en' && (
                              <div style={{ fontSize: '17px' }} className="font-weight-bold text-black">{itemvalue.MenuTittleEnglish}</div>
                            )}
                            {selectedLanguage === 'es' && (
                              <div style={{ fontSize: '14px' }} className="font-weight-bold">{itemvalue.MenuTittleChinese}</div>
                            )}
                          </Row>
                          <Col>
                            <div className='set-height mt-3'>
                              <p style={{
                                fontSize: '14px'
                                // ,maxHeight:'60px',overflow:'hidden'
                              }}>
                                {selectedLanguage === 'en' && (
                                  // <p dangerouslySetInnerHTML={{ __html: (itemvalue.MenuDescriptionEnglish) }} />
                                  <p dangerouslySetInnerHTML={{ __html: getFirstWords(itemvalue.MenuDescriptionEnglish, 6) }} />

                                )}
                                {selectedLanguage === 'es' && (
                                  // <p dangerouslySetInnerHTML={{ __html:(itemvalue.MenuDescriptionChinese) }} />
                                  <p dangerouslySetInnerHTML={{ __html: getFirstWords(itemvalue.MenuDescriptionChinese, 50) }} />

                                )}

                              </p>
                            </div>
                          </Col>
                          <Row className="flex align-items-center">
                            <div className="text-end mt-2 pb-1" style={{ fontSize: '15px', color: '#E85C33', cursor: 'pointer' }} onClick={() => viewModal(index1, item.groupid)}>{translate('View more')}</div>
                          </Row>
                          <div className="">
                            <Row className="">
                              <Col md={6} lg={6} xl={6} sm={6} xs={6} className="mt-2">
                                <div className="" style={{ fontSize: '16px', color: '#E85C33', fontWeight: '400' }}>RM {itemvalue.MenuAmount}</div>
                              </Col>
                              <Col md={6} lg={6} xl={6} sm={6} xs={6} className="justify-end align-items-end text-end">
                                {/* {firstData.ChildrenOrder ===0 ?  <Button className="" style={{
                    width: '60%',
                    backgroundColor: '#7C7C7C',
                    borderColor: '#7C7C7C',
                    color: 'white',
                    fontSize: '14px',
                    paddingLeft: '1px',
                    paddingRight: '1px',
                    fontWeight:'500'

                  }} onClick={addCart1}>
                  <img src="plusimg.png" className="pe-1"  />
                      {translate('Add')}

                  </Button> : <div> */}
                                {checkCurrMnth.includes(itemvalue.MenuID) || checkNextMnth.includes(itemvalue.MenuID) ? <Button className="" style={{
                                  width: '50%',
                                  backgroundColor: '#7C7C7C',
                                  borderColor: '#7C7C7C',
                                  color: '#fff',
                                  fontSize: '14px',
                                  paddingLeft: '1px',
                                  paddingRight: '1px',
                                  fontWeight: '500'
                                }} onClick={addCart1}>
                                  <span style={{ color: "" }} className="ps-1">+ {translate('Add')}</span>

                                </Button> : menuArray.includes(itemvalue.MenuID) ?
                                  <Button className="" style={{
                                    width: '60%',
                                    backgroundColor: 'white',
                                    borderColor: 'border: 1px solid var(--buttons-green-600, #1EAC81)',
                                    color: 'var(--buttons-green-600, #1EAC81)',
                                    fontSize: '14px',
                                    paddingLeft: '1px',
                                    paddingRight: '1px',
                                    fontWeight: '500'
                                  }}>
                                    <span style={{ color: "" }} className="ps-1">{translate('Added')}</span>

                                    <img onClick={() => deleteCart(firstData.ChildrenID, itemvalue.MenuID)} src="cancl.svg" className="ps-1 " style={{ marginTop: '-6px' }} />

                                  </Button> : <Button className="" style={{
                                    width: '50%',
                                    backgroundColor: '#E85C33',
                                    borderColor: '#E85C33',
                                    color: 'white',
                                    fontSize: '14px',
                                    paddingLeft: '1px',
                                    paddingRight: '1px',
                                    fontWeight: '500'
                                  }}
                                    //  onClick={() => {
                                    //   {
                                    //     setMenuId(itemvalue.MenuID);
                                    //     // setCurrParentId(parentId)
                                    //     // setCurrSclId(schoolId)
                                    //     setCurrChldrnId(firstData.ChildrenID)
                                    //     cnfrmAddCart(itemvalue.MenuID)
                                    //   }
                                    // }}
                                    onClick={() => {
                                      setMenuId(itemvalue.MenuID);
                                      setLoading(true);
                                      getMenuDetail(itemvalue.MenuID);
                                      setCurrChldrnId(firstData.ChildrenID)
                                      setShowMenuDtlModal(true)
                                    }}
                                  >
                                    <img src="plusimg.png" className="pe-1" />
                                    {translate('Add')}
                                  </Button>
                                }


                              </Col>
                            </Row>
                          </div>
                          {/* </Link> */}
                        </div>
                      </Col>
                    ))
                    ) : (
                      // !loading && childrenList.length == 0 ?

                      //   <div style={{ marginLeft: '40px', color: 'darkgray' }}> {translate('No menus found')}
                      //   </div> :
                      null
                    )}
                  </div>
                </Row>
              </>
            ))
            ) : (
              !loading && menuLists.length == 0 ?

                <Col lg={12} xl={6} md={12} >
                  {childrenList.length > 0 ?
                    <Row className="mx-1">
                      <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                        <p style={{ color: '#E85C33' }}>{translate('No Menus Available')}</p>
                      </div>
                    </Row> : null
                  }

                </Col> : null
            )}

            {/* The below code is which chooses the order based on month or day (future purpose)*/}

            {/* <Modal show={showAddPrdctModel} onHide={() => setShowAddprdctModel(false)} dialogClassName="modal-dialog-centered"
              centered>
              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} >Add Products</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >
                <>
                  <div className='text-center mx-2'>
                    <Row>
                      <Col style={{ cursor: 'pointer' }} onClick={() => { setCurrMnth(true); setCartType('month') }} className={currMnth ? 'border-thicker w-75' : ''}>Month</Col>
                      <Col style={{ cursor: 'pointer' }} onClick={() => { setCurrMnth(false); setCartType('day') }} className={!currMnth ? 'border-thicker  w-75' : ''}>Day</Col>
                    </Row>
                  </div>

                  <div className='mt-3 '>

                    <div className="button-container my-4">
                      {(
                        <>
                          <Row>
                            <Col xl={6}>
                              <button
                                className="date-button w-100 btn btn-light"
                                style={{ backgroundColor: selectedDate != maxDate ? '#E85C33' : '#E2E8F0', color: selectedDate != maxDate ? '#fff' : '#333' }}
                                onClick={() => {
                                  setSelectedDate(minDate);
                                  setLoading(true);
                                  setProdDays([])
                                }}
                              >
                                Current Month
                              </button>
                            </Col>
                            <Col xl={6}>
                              <button
                                style={{ backgroundColor: selectedDate == maxDate ? '#E85C33' : '#E2E8F0', color: selectedDate == maxDate ? '#fff' : '#333' }}
                                className="date-button w-100 btn btn-light"
                                onClick={() => {
                                  setSelectedDate(maxDate);
                                  setLoading(true);
                                  setProdDays([])
                                }}
                              >
                                Next Month
                              </button>
                            </Col>
                          </Row>


                          {currMnth ?
                            <div className='mt-4'>
                              {checkNextMnth.includes(menuId) && selectedDate == maxDate ? <p style={{ color: '#E85C33' }} className='mt-3'>  *{translate('Menu already added for this month')}</p>
                                : checkCurrMnth.includes(menuId) && selectedDate != maxDate ? <p style={{ color: '#E85C33' }} className='mt-3'>  *{translate('Menu already added for this month')}</p>
                                  : <p className='mt-3' style={{ color: '#E85C33' }}>*Charges won't be applied on holidays.</p>
                              }
                            </div>
                            : null
                          }

                        </>
                      )}

                    </div>


                  </div>

                </>
                {!currMnth ?
                  <div className='mt-4'>
                    {loading ?
                      <div className='d-flex justify-content-center'>
                        <div className="justify-content-center spinner"></div>
                      </div> :
                      <>
                        {checkNextMnth.includes(menuId) && selectedDate == maxDate ? <p style={{ color: '#E85C33' }} className='mt-3'> *{translate('Menu already added for this month')} </p>
                          : checkCurrMnth.includes(menuId) && selectedDate != maxDate ? <p style={{ color: '#E85C33' }} className='mt-3'>  *{translate('Menu already added for this month')}</p>
                            : <>
                              {sortedAllPrdctDays?.map((val, ind) => (
                                <div key={ind}>
                                  <Row>
                                    <Col lg={8} xs={9}>
                                      <div style={val.count > 0 ? { fontFamily: 'Rubik', fontSize: '17px', fontStyle: 'normal', fontWeight: 400 } : { color: '#6B6B6B', textDecoration: 'line-through' }}>
                                        <input onClick={() => val.count > 0 ? handleAddProduct(val.day) : null} className='me-2 order-check' value={val.day} type='checkbox' checked={val.count > 0 ? prodDays.includes(val.day) : false} />
                                        {dayNamesMapping[val.day]}

                                        <p className='ms-2' style={{ display: 'inline', lineHeight: '29.052px', color: '#6B6B6B', fontFamily: 'Rubik' }}>

                                          {(val.count > 0 ? ("(" + val.date + ") ") : null) + val.count + " days"}
                                        </p>
                                      </div>
                                    </Col>
                                    <Col lg={4} xs={3}>
                                      <div style={{ color: 'red' }} className='text-end me-2'>
                                        RM {val.amount}
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              <p className='mt-3' style={{ color: '#E85C33' }}>*{translate("Charges won't be applied on holidays")}.</p>
                            </>}

                      </>
                    }

                  </div>
                  : null
                }
                {checkNextMnth.includes(menuId) && selectedDate == maxDate ? <button className='btn btn-danger w-100 mt-3'> {translate('Select a valid Month')}</button> :
                  checkCurrMnth.includes(menuId) && selectedDate != maxDate ? <button className='btn btn-danger w-100 mt-3'> {translate('Select a valid Month')}</button> :
                    cartType == 'day' && prodDays.length == 0 ? <button className='btn btn-danger w-100 mt-3'>Pick a day</button> :
                      <button onClick={() => { setIsproceed(true) }} style={{ backgroundColor: '#E85C33' }} className='btn btn-danger w-100 mt-3'>{translate('Add Product')}</button>}
              </Modal.Body>

            </Modal> */}

            {/* The above code is which chooses the order based on month or day (future purpose)*/}

            <Modal show={showModal} onHide={handleCloseModal} dialogClassName="modal-dialog-centered"
              centered>
              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} dangerouslySetInnerHTML={{ __html: modalTitle }}></Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >

                <div dangerouslySetInnerHTML={{ __html: modalRemark }} />
                <hr style={{ borderColor: '#ccc' }} />
                <div dangerouslySetInnerHTML={{ __html: modalDesciption }} />

                {menuImages ? (
                  <div id="carouselExampleIndicators" class="carousel slide">
                    <div class="carousel-indicators">
                      {menuImages.map((val, index) => (
                        <button
                          key={index}
                          type="button"
                          data-bs-target="#carouselExampleIndicators"
                          data-bs-slide-to={index}
                          className={index === 0 ? "active" : ""}
                          aria-current={index === 0 ? "true" : ""}
                          aria-label={`Slide ${index + 1}`}
                        ></button>
                      ))}
                    </div>

                    <div class="carousel-inner">
                      {menuImages.map((val, index) => (
                        <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
                          <img src={val.MenuImage} className="d-block w-100" alt={`Slide ${index + 1}`} />
                        </div>
                      ))}
                    </div>

                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                      <div className='color-custom'>
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span class="visually-hidden">Previous</span>
                      </div>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                      <div className='color-custom'>
                        <span class="carousel-control-next-icon " aria-hidden="true"></span>
                        <span class="visually-hidden">Next</span>
                      </div>

                    </button>
                  </div>
                ) : null}

                <hr style={{ borderColor: '#ccc' }} />
                <div dangerouslySetInnerHTML={{ __html: notes }} />


                <Row>

                </Row>


              </Modal.Body>

            </Modal>

            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showSelectChildModal}
              onHide={() => setShowSelectChildModal(false)}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">{translate('Select Child')}</Modal.Title>
              </Modal.Header>
              <div className="my-4 mx-4" style={{ maxHeight: '400px', overflowY: 'auto', overflowX: 'hidden' }}>
                {childrenList && childrenList.length > 0 ? (childrenList.map((item, index) => (
                  <div key={index} style={{ cursor: 'pointer' }} onClick={() => appendChild(index)}>
                    {index != 0 && (
                      <hr className="mx-2" />
                    )}

                    <Row className="flex align-items-center ">
                      <Col xs={3} sm={4} md={4} lg={4} xl={2} className="text-end">
                        <img src={Image_Url + item.ChildrenImage} alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                      </Col>
                      <Col xs={7} sm={6} md={6} lg={6} xl={8} className="text-start">
                        <div style={schoolName} className="font-weight-bold text-black ">{item.ChildrenName}</div>
                        <div style={placeColor} className="placecolor  text-muted  color-white ">{item.ChildrenGrade}</div>
                      </Col>
                    </Row>
                  </div>
                ))
                ) : (
                  <Row className="flex align-items-center ">
                  </Row>
                )}

              </div>
            </Modal>

            <Toast
              show={showToast1}
              onClose={() => setShowToast1(false)}
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                minWidth: '194px',
                maxWidth: '25px',
                borderRadius: '35px',
              }}
            >

              <Toast.Body style={{ color: '#222222', fontSize: '14px', padding: '6px' }} className="ms-2">
                {translate('Item Removed From Cart')}
              </Toast.Body>

            </Toast>
            <Toast
              show={showToast}
              onClose={() => setShowToast(false)}
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                minWidth: '155px',
                maxWidth: '25px',

                borderRadius: '35px',
              }}
            >

              <Toast.Body style={{ color: '#222222', fontSize: '14px', padding: '6px' }} className="ms-2">
                {translate('Item Added to cart')}
              </Toast.Body>

            </Toast>
            <Toast
              show={showToastone}
              onClose={() => setShowToastone(false)}
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 1,
                minWidth: '150px',
                maxWidth: '300px',

                borderRadius: '8px',
                backgroundColor: "#FEDCDC",
                borderColor: "#FEDCDC"
              }}
            >

              <Toast.Body style={{ color: '#222222', fontSize: '14px', padding: '8px' }} className="ms-1">
                <img src="caution.png" className="pe-1" /><span style={{ color: "#FA5252" }}> {translate('You can add only one item per meal')}</span>
              </Toast.Body>

            </Toast>


            <Modal show={showMenuDtlModal} onHide={() => setShowMenuDtlModal(false)} dialogClassName="modal-dialog-centered"
              centered>
              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }}>{!loading ? (menuDetails?.menuType == 'month' ? translate('Month') : translate('Week')) : ''}</Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >
                {loading ?
                  <div className='d-flex justify-content-center'>
                    <div className="justify-content-center spinner"></div>
                  </div> :
                  <>
                    {menuDetails?.menuType == 'month' ? <>
                      <p>{translate("This menu will be placed from")} {menuDetails?.menuStartDate} {translate("to")} {menuDetails?.menuEndDate}</p>
                      <p style={{ color: '#E85C33' }} className='color-red'>{translate('Amount')} : RM {menuDetails?.menuAmount}</p>
                      <button onClick={() => { setIsproceed(true); setShowMenuDtlModal(false) }} style={{ backgroundColor: '#E85C33' }} className='btn btn-danger w-100 '>{translate('Add Product')}</button>
                    </> : <>

                      <p>
                        {translate("This menu will be placed on")} {menuDetails?.menuDay}{"'s "}
                        {menuDetails?.menuDate.map(val => `(${val}) `)}
                      </p>                      <p style={{ color: '#E85C33' }} className='color-red'>{translate('Amount')} : RM {menuDetails?.menuAmount}</p>
                      <button onClick={() => { setIsproceed(true); setShowMenuDtlModal(false) }} style={{ backgroundColor: '#E85C33' }} className='btn btn-danger w-100 '>{translate('Add Product')}</button>

                    </>
                    }

                  </>}


                <Row>

                </Row>


              </Modal.Body>

            </Modal>

          </Container>



        </>
      )
      }
    </div >
  );

}










