// Loader.js
import React from 'react';
import './custom.css'; // Import your custom CSS file
import { useTranslation } from './LanguageContext';

const Loader = () => {
  const { translate, changeLanguage } = useTranslation();

  return (
    <div className="loader">
      <div className="spinner"></div>
      <div className="loading-text">{translate("Loading")}...</div>
    </div>
  );
};

export default Loader;
