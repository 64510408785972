import React from 'react'
import './custom.css'
import {Container} from 'react-bootstrap'
import {Row} from 'react-bootstrap'
import {Col} from 'react-bootstrap'
import MyNavbar from './Navbar'; 
import {ListGroup} from 'react-bootstrap'
import { Form } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useTranslation } from './LanguageContext';




 export default function MyEmptyprof(){
    const boxStyle={
        borderRadius: '5px',

    }
    const remove={
        color: '#E85C33', 
        fontSize:'15px'


    }
    const schoolName={
        fontSize:"15px"

    }
    const placeColor={
        fontSize:"15px"

    }
    const total={
        color: '#E85C33', 
        fontSize:'17px'

    }
     const order={
        color:'#303A5B',
        fontSize:'15px',
        fontWeight:'bold'
     }
     const subtotal={
        color:'#7C7C7C'

     }
     const check={
        color:'#303A5B',
       

     }
    return(
        <>
        <MyNavbar/>
       <Container>
       <Col  xs={5} sm={7} md={5} lg={3} xl={3} className="mt-4">
            <Link  to="/qrcalendar" className="link-no-underline ">
          <img src="leftarrow.png" alt="School Logo"className="pe-2 " /> Empty
          </Link>
        </Col>

      
      
      <Row className="my-5">
      
<Col lg={5} xl={5}>
<div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
    
    <Row className="flex align-items-center  ">
          
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-start">
             <div className=""style={{color:'#1EAC81'}}>Current Plan</div>
            </Col>
            
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
        
            <div>1st - 30th Aug</div>
            </Col>
          </Row>


          <Row>
<Col md={7} lg={7} xl={6} sm={6} xs={6} className="mt-2">
<ul className="text-start" style={{fontSize:'14px',color:'#7C7C7C'}}><li>Breakfast-Veg Premium</li>
<li>Lunch-Veg Standard</li>
<li>Snacks-Veg Super Combo</li></ul>
</Col>
<Col md={5} lg={5} xl={6} sm={6} xs={6}className="justify-end align-items-end mt-5">
    <div className="text-end">
<Button className=""style={{
      width: '60%',
      backgroundColor: 'black', 
      borderColor: 'black',    
      color:'white' ,
      fontSize:'15px',
      paddingLeft: '1px',
      paddingRight:'1px'
    }}>
Renew Now
    </Button></div>
</Col>
          </Row>









    </div>
</Col>
    
<Col lg={5} xl={5}>

<div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
    
    <Row className="flex align-items-center  ">
          
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-start">
             <div className=""style={{color:'#1EAC81'}}>Current Plan</div>
            </Col>
            
            <Col xs={6} sm={6} md={6} lg={6} xl={6} className="text-end">
        
            <div>1st - 30th Aug</div>
            </Col>
          </Row>


          <Row>
<Col md={7} lg={7} xl={6} sm={6} xs={6} className="mt-2">
<ul className="text-start" style={{fontSize:'14px',color:'#7C7C7C'}}><li>Breakfast-Veg Premium</li>
<li>Lunch-Veg Standard</li>
<li>Snacks-Veg Super Combo</li></ul>
</Col>
<Col md={5} lg={5} xl={6} sm={6} xs={6}className="justify-end align-items-end mt-5">
    <div className="text-end">
<Button className=""style={{
      width: '60%',
      backgroundColor: 'black', 
      borderColor: 'black',    
      color:'white' ,
      fontSize:'15px',
      paddingLeft: '1px',
      paddingRight:'1px'
    }}>
Renew Now
    </Button></div>
</Col>
          </Row>
          


          




    </div>
   

</Col>

       
       <Col lg={2} xl={2}className="text-center">
       <img src="qr.png"/>

       <div className="text-center mt-4 pt-2">
<Button className=""style={{
      width: '60%',
      backgroundColor: '#292D32', 
      borderColor: '#292D32',    
      color:'white' ,
      fontSize:'15px',
      paddingLeft: '1px',
      paddingRight:'1px',
      borderRadius:'10px'
    }}>
        Download<img src="download.png" className="ps-2"/>
    </Button></div>

    <div className="text-center mt-3">
<Button className=""style={{
      width: '60%',
      backgroundColor: 'white', 
      borderColor: '#292D32',    
      color:'#292D32' ,
      fontSize:'15px',
      paddingLeft: '1px',
      paddingRight:'1px',
      borderRadius:'10px'
    }}>
Share<img src="share.png"className="ps-2"/>
    </Button></div>
       </Col>

      </Row>
      </Container>


        </>
    );

 }










 