import React, { createContext, useState, useContext } from 'react';
import en from './en.json';
import es from './es.json';
import ma from './ma.json';

const languages = {
  en,
  es,
  ma
};

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [notiCnt,setNotiCnt]=useState(0);

  const translate = (key, params = {}) => {
    const translation = languages[language][key] || key;

    if (!translation) {
      console.warn(`Translation not found for key: ${key}`);
      return key;
    }

    return Object.keys(params).reduce(
      (acc, param) => acc.replace(`{${param}}`, params[param]),
      translation
    );
  };

  const changeLanguage = (lang) => {
    if (languages[lang]) {
      setLanguage(lang);
      setSelectedLanguage(lang);
    } else {
      console.warn(`Language not supported: ${lang}`);
    }
  };
  const setNotification = (cnt) => {
    setNotiCnt(cnt)
  };

  return (
    <LanguageContext.Provider value={{ language, translate, changeLanguage, selectedLanguage,setNotification,notiCnt }}>
      {children}
    </LanguageContext.Provider>
  );
};

export const useTranslation = () => useContext(LanguageContext);
