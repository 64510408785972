import React, { useCallback, useEffect, useState } from 'react';

import './custom.css'
import MyNavbar from './Navbar'
import { Container } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from './LanguageContext';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { useParams } from 'react-router-dom';
import { Image_Url } from './globalvariable/globe';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';



export default function MyPayment() {
  const [loading, setLoading] = useState(false)

  const { selectedLanguage } = useTranslation();

  const { translate, changeLanguage } = useTranslation();
  const [stuDetail, setStuDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isReedem, setIsReedem] = useState(false)
  const [isNotReedem, setIsNotReedem] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [status, setStatus] = useState([])
  const keyExistsx = localStorage.getItem('hnhpapacartCount') !== null;
  const [showRenewInfo, setShowRenewInfo] = useState(false)


  const [cartCount, setcartCount] = useState(keyExistsx ? localStorage.getItem('hnhpapacartCount') : 0);
  const [isStatus, setIsStatus] = useState([])
  const [showMenuInfo, setShowMenuInfo] = useState(false)


  const updateStatus = (val) => {
    if (status.includes(val)) {
      // If val is in status array, remove it
      setStatus(prevStatus => prevStatus.filter(item => item !== val));
      setIsStatus(prevStatus => prevStatus.filter(item => item !== val));

    } else {
      // If val is not in status array, add it
      setStatus(prevStatus => [...prevStatus, val]);
      setIsStatus(prevStatus => [...prevStatus, val]);
    }
  };

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const handleSelectedStatus = (e) => {
    const updtStatus = e.target.value
    updateStatus(updtStatus)
  }
  const [profileImage, setProfileImage] = useState(null);
  const [showChangesModal, setShowChangesModal] = useState(false)
  // Handle profile image selection
  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setIsReedem(false)
    setIsNotReedem(false)
    setIsValid(false)
  };
  const dayNamesMapping = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };
  const handleShowModal = () => {
    setShowModal(true);
  };
  const boxStyle = {
    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  }

  const schoolName = {
    fontSize: "15px"
  };
  const placeColor = {
    fontSize: "15px"
  };
  const buttoncolor = {
    backgroundColor: '#F17C38',
    border: 'none'
  }


  const [childrenFilterData, setChildrenFilterData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date())
  const [todate, setToDate] = useState(new Date())
  // const [fromdatee, setFromdatee] = useState(fromDate.toISOString().split('T')[0]); // Save formatted date in 'yyyy-mm-dd' format
  // const [todatee, setTodatee] = useState(todate.toISOString().split('T')[0]); // Save formatted date in 'yyyy-mm-dd' format

  const { id } = useParams();
  const [err, setErr] = useState(false)
  const [childrenId, setChildrenId] = useState(null);
  const [childrenData, setChildrenData] = useState({});
  const dateFromObject = new Date(fromDate);
  const dateToObject = new Date(todate);
  const [menus, setMenus] = useState([])
  const [infoInd, setInfoInd] = useState(0)
  const [renewData, setRenewData] = useState([])

  const sendApi = {
    ChildrenID: childrenId,
    orderID: id,
    FromDate: dateFromObject.toISOString().split('T')[0],
    ToDate: dateToObject.toISOString().split('T')[0],
    ScanStatus: status.join(',')
    // ScanStatus: 'redeem,not-redeemed,'
  }
  console.log(childrenData, "child da")
  const handleCloseChangesModal = useCallback(async () => {
    setFromDate(new Date());
    setToDate(new Date());
    setStatus([]);
    setIsStatus([]);
    setChildrenFilterData([]);

    await fetchData();
    fetchFilterData();

  }, [childrenId]);

  // Rest of your component code


  const fetchData = async () => {
    setLoading(true)

    let bodyFormData = new FormData()

    bodyFormData.append('OrderID', id)
    setErr(false)
    let response = await axios({
      method: 'post',
      url: Base_Url + '/vieworderdetails',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        setChildrenData(res.data.result);
        setMenus(res.data.result.Menus)
        setChildrenId(res.data.result.ChildrenID);
        setLoading(false)
      })
      .catch((err) => {
        setErr(true);
        console.log('Error fetching data:', err.message);
        // setLoading(false)

      });

  };
  let navigate = useNavigate()
  const renew1 = async (id) => {

    let bodyFormData = new FormData()
    bodyFormData.append('OrderID', id)
    let responce = await axios({
      method: 'post',
      url: Base_Url + '/renewmenu',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          getcartCount();
          navigate('/cart')
        } else {
          alert(res.data.message)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
        // setLoading(false)

      });

  }
  const renew = async (ordrId) => {
    setShowRenewInfo(true)
    let bodyFormData = new FormData()
    bodyFormData.append('orderID', id)
    bodyFormData.append('childID', childrenId)

    let responce = await axios({
      method: 'post',
      url: Base_Url + '/checkRenewal',
      data: bodyFormData,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        if (res.data.status === true) {
          // getcartCount();
          // navigate('/cart')
          setRenewData(res.data.messages)
        } else {
          // alert(res.data.message)
        }
      })
      .catch((err) => {
        console.log('Error fetching data:', err);
      });

  }
  const getcartCount = async () => {
    const parentId = await localStorage.getItem('hnhpapauserId');
    let response = await axios({
      method: 'post',
      url: `${Base_Url}/cartcount`,
      data: {
        ParentID: parentId,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setcartCount(localStorage.setItem('hnhpapacartCount', res.data.result.cartItemCount));
        } else {
          alert(res.data.message);
        }
      }).catch((error) => {
        console.error('Error', error)
        // setLoading(false)

      })
  }

  const fetchFilterData = async () => {

    let bodyFormData = new FormData()

    // bodyFormData.append('ChildrenID', childrenId)
    // bodyFormData.append('FromDate', fromdatee)

    // bodyFormData.append('ToDate', todatee)
    // bodyFormData.append('ScanStatus', 'not-redeemed')

    setErr(false)
    let response = await axios({
      method: 'post',
      url: Base_Url + '/scanchildpreview',
      data: sendApi,
      dataType: 'json',
      headers: { "Content-Type": "multipart/form-data" }
    })
      .then(async (res) => {
        setChildrenFilterData(res.data.result);
      })
      .catch((err) => {
        setErr(true);
        console.log('Error fetching data:', err.message);
        // setLoading(false)

      });

  };
  function formatDateToDisplay(dateStr) {
    const date = new Date(dateStr);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  }
  useEffect(() => {
    fetchData();
    if (childrenId) {
      // Call fetchFilterData when childrenId changes
      fetchFilterData();
    }
  }, [childrenId]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <>


          <Container>
            <MyNavbar />


            <div className="my-4">

              <Row className=" ">
                <Col xs={5} sm={7} md={5} lg={3} xl={3}>
                  <Link to="/history" className="link-no-underline">
                    <img src={process.env.PUBLIC_URL + '/leftarrow.png'} alt="School Logo" className="pe-1" />
                  </Link>
                </Col>
                <Col xs={7} sm={5} md={7} lg={9} xl={9} className="text-end">
                  <Link to={`/calendar/${childrenId}/${id}`}><img src={process.env.PUBLIC_URL + '/calendar.png'}></img></Link>
                </Col>


              </Row>
            </div>


            <Row className="mx-1" >
              <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">

                {/* <Row className="flex align-items-center ">
                  <Col xs={2} sm={2} md={2} lg={1} xl={1} className="child-pay-pic">
                    <img src={Image_Url + childrenData.ChildrenImage} alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />

                  </Col>
                  <Col xs={4} sm={3} md={6} lg={9} xl={8} className="text-start me-3 text-sm-center">
                    <div style={{ fontSize: '18px' }} className="text-black ">{childrenData.ChildrenName}</div>
                    <div style={placeColor} className="placecolor  text-muted  color-white ">{childrenData.ChildrenGrade}</div>
                  </Col>

                  <Col xs={6} sm={7} md={4} lg={2} xl={3} className="text-end child-pay-btn">

                    {childrenData.renewmenu == 1 ?

                      <Button className="" style={{
                        width: '40%',
                        backgroundColor: 'black',
                        borderColor: 'black',
                        color: 'white',
                        fontSize: '13px',
                        paddingLeft: '1px',
                        paddingRight: '1px',
                      }} onClick={() => renew(childrenData.OrderID)}>
                        {translate('Renew Now')}
                      </Button>
                      : <>{childrenData.renewmenumessage}</>

                    }

                  </Col>
                </Row> */}


                <Row className="flex align-items-center">

                  <Col xs={1} sm={2} md={2} lg={2} xl={2}>

                    <div className="d-flex align-items-center">
                      <span style={{ flex: '0 0 auto', marginRight: '1rem' }}>
                        <img src={Image_Url + childrenData.ChildrenImage} style={{ width: '44px', height: '44px', borderRadius: '44px' }} alt="School Logo" />
                      </span>
                      <div className="text-start">
                        <div style={{ fontSize: '18px' }} className="font-weight-bold text-black">
                          {childrenData.ChildrenName}
                        </div>
                        <div style={placeColor} className="placecolor text-muted color-white">
                          {childrenData.ChildrenGrade}
                        </div>
                      </div>
                    </div>

                  </Col>


                  <Col xs={2} sm={2} md={2} lg={2} xl={2} className="text-end">
                    {/* <div className="text-end" style={{ fontSize: '14px' }}>
                      {item.daysLeft}
                    </div> */}
                    {childrenData.renewmenu == 1 ?

                      <Button className="" style={{
                        width: '40%',
                        backgroundColor: 'black',
                        borderColor: 'black',
                        color: 'white',
                        fontSize: '13px',
                        paddingLeft: '1px',
                        paddingRight: '1px',
                      }} onClick={() => renew(childrenData.OrderID)}>
                        {translate('Renew Now')}
                      </Button>
                      : <>{childrenData.renewmenumessage}</>

                    }
                  </Col>
                  <Col xs={9} sm={8} md={8} lg={8} xl={8}  >
                    <div className="text-end my-3" style={{ color: '#6B6B6B' }}>
                      *{translate('Please download and MUST print out QR, students need to scan card in order to get food.')}
                    </div>
                  </Col>
                </Row>




                <Row className="mt-3">
                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>
                    <div className="text-start " style={{ color: '#303A5B', fontSize: '15px', fontWeight: '600' }}>{translate('Order Contains')}</div>
                  </Col>

                </Row>

                {childrenData.Menus && childrenData.Menus.length > 0 ? (
                  childrenData.Menus.map((menu, index) => (
                    <>
                      <Row className="mt-1" key={index}>
                        <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                          <div className="text-start mb-2" style={{ fontSize: '17px' }}>


                            {selectedLanguage === 'en' && (
                              <span > {menu.MenuTittleEnglish}</span>
                            )}
                            {selectedLanguage === 'es' && (
                              <span> {menu.MenuTittleChinese}</span>

                            )}


                            {selectedLanguage === 'en' && (
                              <span> {menu.MenuTypeEnglish}</span>
                            )}
                            {selectedLanguage === 'es' && (
                              <span> {menu.MenuTypeChinese}</span>

                            )}
                            <img
                              onClick={() => { setShowMenuInfo(true); setInfoInd(index) }}
                              className=" ms-1" src={process.env.PUBLIC_URL + '/info_Icon.png'} alt="info"
                              style={{ width: '18px', height: '18px', }}>

                            </img>
                          </div>
                        </Col>
                        {/* <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                        <div className="text-end" style={{ color: '#303A5B', fontSize: '15px' }}>{menu.MenuTypeEnglish}</div>
                       </Col> */}

                        {/* <div className="text-start " style={{ color: '#303A5B', fontSize: '15px', fontWeight: '600' }}>{translate('Remark')}</div> */}

                        {selectedLanguage === 'en' && (

                          <div style={{ color: '#303A5B', fontSize: '15px', marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: menu.remarkenglish }}></div>

                        )}
                        {selectedLanguage === 'es' && (
                          <div style={{ color: '#303A5B', fontSize: '15px', marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: menu.remarkchinese }}></div>

                        )}
                        <Col lg={12} xl={12} md={12} xs={12} sm={12}>


                          {selectedLanguage === 'en' && (
                            <div style={{ color: '#303A5B', fontSize: '15px', marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: menu.MenuDescriptionEnglish }}></div>

                          )}
                          {selectedLanguage === 'es' && (
                            <div style={{ color: '#303A5B', fontSize: '15px', marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: menu.MenuDescriptionChinese }}></div>

                          )}

                        </Col>
                        {selectedLanguage === 'en' && (

                          <div style={{ color: '#303A5B', fontSize: '15px', marginLeft: '10px' }} dangerouslySetInnerHTML={{
                            __html: menu.notesenglish
                          }}></div>

                        )}
                        {selectedLanguage === 'es' && (
                          <div style={{ color: '#303A5B', fontSize: '15px', marginLeft: '10px' }} dangerouslySetInnerHTML={{ __html: menu.noteschinese }}></div>

                        )}
                      </Row>
                    </>
                  ))
                ) : (
                  !loading && !childrenData.Menus ?
                    <div style={boxStyle} className="border border-gray-100 my-1 w-25 py-2 px-3">
                      <p style={{ color: '#E85C33' }}>{translate('No Menus')}</p>
                    </div>
                    : null
                )}

              </div>

            </Row>



            <Row style={{ background: 'var(--layout-100, #F9F9F9)' }} className="mx-1 mt-3">
              <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                <Row className="mt-1">
                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-start text-black" style={{ fontSize: '17px' }}>{translate('Payment Details')}</div>
                  </Col>

                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-end" style={{ color: '#303A5B', fontSize: '15px' }}>{translate('Payment')} {childrenData.PaymentStatus == 'Pending' && selectedLanguage == 'en' ? 'Incomplete' : translate(childrenData.PaymentStatus)}</div>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-start text-muted" style={{ fontSize: '15px' }}>{translate('Amount')}</div>
                  </Col>

                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-end" style={{ color: '#303A5B', fontSize: '15px' }}>{"RM " + childrenData.PaymentAmount}</div>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col lg={6} xl={6} md={6} xs={5} sm={6}>

                    <div className="text-start text-muted" style={{ fontSize: '15px' }}>{translate('Transaction ID')}</div>
                  </Col>

                  <Col lg={6} xl={6} md={6} xs={7} sm={6}>

                    <div className="text-end" style={{ color: '#303A5B', fontSize: '15px' }}>{childrenData.PaymentTransactionID ? childrenData.PaymentTransactionID : '-'}</div>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-start text-muted" style={{ fontSize: '15px' }}>{translate('Payment Type')}</div>
                  </Col>

                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-end" style={{ color: '#303A5B', fontSize: '15px' }}>{translate(childrenData.PaymentType)}</div>
                  </Col>
                </Row>

                <Row className="mt-1">
                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                    <div className="text-start text-muted" style={{ fontSize: '15px' }}>{translate('Date & Time')} </div>
                  </Col>

                  <Col lg={6} xl={6} md={6} xs={6} sm={6}>
                    <div className="text-end" style={{ color: '#303A5B', fontSize: '15px' }}>{translate(childrenData.PaymentCreatedOn)}</div>
                  </Col>
                </Row>
              </div>
            </Row>

            <Row>
              <Col className='text-end me-2'>
                <img onClick={() => setShowChangesModal(true)} src={process.env.PUBLIC_URL + '/filter_list.svg'} />
              </Col>
            </Row>

            {childrenFilterData && childrenFilterData.length > 0 ? (
              childrenFilterData.map((menu, index) => (
                <Row className=" mx-1 mt-3" key={index} >
                  <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                    <Row className="mt-1 mb-3">
                      <Col className='' lg={8} xl={9} md={6} xs={5} sm={5}>
                        <p style={{ fontSize: '17px', fontWeight: 'bold' }}>{formatDateToDisplay(menu.ScanOrderMonthDate)}</p>

                        <div className="text-start me-2" style={{ color: '#303A5B', fontSize: '15px', fontWeight: 'bold' }}>
                          {selectedLanguage === 'en' && (
                            <>

                              {menu.MenuTittleEnglish}
                            </>

                          )}
                          {selectedLanguage === 'es' && (
                            <>
                              {menu.MenuTittleChinese}
                            </>

                          )}
                        </div>
                      </Col>

                      {/* <Col lg={6} xl={6} md={6} xs={6} sm={6}>

                        <div className="text-end" >
                          <Button className=""
                            style={{
                              width: '20%', backgroundColor: menu.ScanStatus == 'redeem' ? '#188A67' : '#E85C33',
                              borderColor: menu.ScanStatus == 'redeem' ? '#188A67' : '#E85C33',
                              color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px'
                            }}>
                            {translate(menu.ScanStatus)}
                          </Button> */}
                      {/* {translate(menu.ScanStatus)} */}
                      {/* </div>
                      </Col> */}
                      <Col lg={4} xl={3} md={6} xs={7} sm={7}>
                        <div className="text-end">
                          {menu.ScanStatus == 'not-redeemed' ?
                            <Button className="" style={{ width: '60%', backgroundColor: '#E85C33', borderColor: '#E85C33', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px' }}>{translate(menu.ScanStatus)}</Button>
                            :
                            <Button className="" style={{ width: '60%', backgroundColor: '#188A67', borderColor: '#188A67', color: 'white', fontSize: '15px', paddingLeft: '1px', paddingRight: '1px', borderRadius: '15px' }}>{translate(menu.ScanStatus)}</Button>
                          }
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12} md={10} lg={10} xl={10}>
                        <div className="" style={{ fontSize: '15px' }}>
                          {selectedLanguage === 'en' && (
                            <>

                              {menu.MenuTypeEnglish}
                            </>

                          )}
                          {selectedLanguage === 'es' && (
                            <>
                              {menu.MenuTypeChinese}
                            </>

                          )}</div>
                        {/* <ul style={{fontSize:'15px'}}>
            <li>
          肉酱意大利面+水果 Spaghetti Bolognese + Fruits</li>
          <li>芝士午餐肉包+Milo Cheese & Luncheon Meat Bun + Milo</li>
          <li>脆皮麦片鸡饭 Crispy Cereal Chicken Rice</li>
          <li>汤/咖喱味一面 Soup/Curry Weiyi Noodles</li>
          <li>炒果条+水果 Fried Keow Tiao + Fruits
          </li>
          </ul>
          
          <div style={{fontSize:'15px',fontWeight:'bold'}}>Week 2</div>
          <ul  style={{fontSize:'15px'}}>
          <li>通心粉+水果 Macaroni + Fruits</li>
          <li>鸡肉火腿三明治+酸奶饮料 Chicken Sandwich + Yogurt Drink</li>
          <li>中式炒饭 Chinese Fried Rice</li>
          <li>鸡丝河粉 Shredded Chicken Hor Fun</li>
          <li>云吞面+水果 Dried Wan Tan Noodles + Fruits</li>
          </ul>
          
          <div style={{fontSize:'15px',fontWeight:'bold'}}>Week 3</div>
          <ul  style={{fontSize:'15px'}}>
          
          <li>培根蛋面+水果 Carbonara + Fruits</li>
          <li>鸡肉松包+美禄 Chicken Floss Bun + Milo</li>
          <li>炸腐乳鸡饭 Fried Fermented Bean Curd Chicken Rice</li>
          <li>汤/咖喱鱼丸面 Soup/Curry Fish Ball Noodles</li>
          <li>炒米粉+水果 Fried Mi Hun + Fruits</li>
          </ul>
          
          
          <div style={{fontSize:'15px',fontWeight:'bold'}}>Week 4</div>
          <ul  style={{fontSize:'15px'}}>
          <li>螺旋螺丝粉 + 水果 Fusilli Aglio Olio + Fruits</li>
          <li>烤面包+酸奶饮料 Baked Bun + Yogurt Drink</li>
          <li>椰浆饭 Nasi Lemak</li>
          <li>汤/冬阴功一米 Soup/Tom Yum Yi Mi</li>
          <li>板米干+水果 Dried Pan Mi + Fruits</li>
          </ul> */}
                      </Col>
                      <Col xs={12} sm={12} md={2} lg={2} xl={2} className="d-flex flex-column align-items-end justify-content-end">
                        {menu.ScanTimeFrom ? <div className="text-muted">{"@" + menu.ScanTimeFrom} </div> : null}

                      </Col>
                    </Row>
                  </div>
                </Row>
              ))
            ) : (
              <div style={boxStyle} className="border border-gray-100 my-2 w-100 py-2 px-3">
                <p style={{ color: '#E85C33' }}>{translate('No Datas')}</p>
              </div>
              // <div > {translate('No Datas')}</div>
            )}

            {/* <Row className="mx-1 mt-3">
        <div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
        <Row className="mt-1 mb-3">
<Col lg={6} xl={6} md={6} xs={6} sm={6}>

    <div className="text-start "style={{color:'#303A5B',fontSize:'15px',fontWeight:'bold'}}>{translate('Morning')}</div>
    </Col>

    <Col lg={6} xl={6} md={6} xs={6} sm={6}>

    <div className="text-end" style={{color:'#188A67',fontSize:'15px'}}>{translate('Redeemed')}</div>
    </Col>
</Row>
         <Row><Col xs={12} sm={12} md={10} lg={10} xl={10}>
<div className="" style={{fontSize:'15px',fontWeight:'bold'}}>week 1</div>
<ul style={{fontSize:'15px'}}>
  <li>
肉酱意大利面+水果 Spaghetti Bolognese + Fruits</li>
<li>芝士午餐肉包+Milo Cheese & Luncheon Meat Bun + Milo</li>
<li>脆皮麦片鸡饭 Crispy Cereal Chicken Rice</li>
<li>汤/咖喱味一面 Soup/Curry Weiyi Noodles</li>
<li>炒果条+水果 Fried Keow Tiao + Fruits
</li>
</ul>

<div style={{fontSize:'15px',fontWeight:'bold'}}>Week 2</div>
<ul  style={{fontSize:'15px'}}>
<li>通心粉+水果 Macaroni + Fruits</li>
<li>鸡肉火腿三明治+酸奶饮料 Chicken Sandwich + Yogurt Drink</li>
<li>中式炒饭 Chinese Fried Rice</li>
<li>鸡丝河粉 Shredded Chicken Hor Fun</li>
<li>云吞面+水果 Dried Wan Tan Noodles + Fruits</li>
</ul>

<div style={{fontSize:'15px',fontWeight:'bold'}}>Week 3</div>
<ul  style={{fontSize:'15px'}}>

<li>培根蛋面+水果 Carbonara + Fruits</li>
<li>鸡肉松包+美禄 Chicken Floss Bun + Milo</li>
<li>炸腐乳鸡饭 Fried Fermented Bean Curd Chicken Rice</li>
<li>汤/咖喱鱼丸面 Soup/Curry Fish Ball Noodles</li>
<li>炒米粉+水果 Fried Mi Hun + Fruits</li>
</ul>


<div style={{fontSize:'15px',fontWeight:'bold'}}>Week 4</div>
<ul  style={{fontSize:'15px'}}>
<li>螺旋螺丝粉 + 水果 Fusilli Aglio Olio + Fruits</li>
<li>烤面包+酸奶饮料 Baked Bun + Yogurt Drink</li>
<li>椰浆饭 Nasi Lemak</li>
<li>汤/冬阴功一米 Soup/Tom Yum Yi Mi</li>
<li>板米干+水果 Dried Pan Mi + Fruits</li>
</ul>
</Col>
<Col  xs={12} sm={12} md={2} lg={2} xl={2}className="d-flex flex-column align-items-end justify-content-end">
<div className="text-muted">@9.30 a.m </div>
</Col>
</Row>
</div>
          </Row>




          <Row className="mx-1 mt-3">
        <div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
        <Row className="mt-1 mb-3">
<Col lg={6} xl={6} md={6} xs={6} sm={6}>

    <div className="text-start "style={{color:'#303A5B',fontSize:'15px',fontWeight:'bold'}}>{translate('Lunch')}</div>
    </Col>

    <Col lg={6} xl={6} md={6} xs={6} sm={6}>

    <div className="text-end" style={{color:'#188A67',fontSize:'15px'}}>{translate('Redeemed')}</div>
    </Col>
</Row>
         


<div style={{fontSize:'15px',fontWeight:'bold'}}>Mixed Rice</div>
<ol style={{fontSize:'15px'}}>
<li>鸡肉 Chicken
</li>
<li>肉类 / 鱼 Chicken / Fish Fillet
</li>
<li>鸡蛋 Eggs

</li>
<li>豆腐 TouFu
</li>
<li> 青菜 Vegetables
</li>
</ol>
</div>
          </Row>
   




          <Row className="mx-1 mt-3">
        <div style={boxStyle}className="border border-gray-100 my-1  py-3 px-3">
        <Row className="mt-1 mb-3">
<Col lg={6} xl={6} md={6} xs={6} sm={6}>

    <div className="text-start "style={{color:'#303A5B',fontSize:'15px',fontWeight:'bold'}}>{translate('Snacks')}</div>
    </Col>

    <Col lg={6} xl={6} md={6} xs={6} sm={6}>

    <div className="text-end" style={{color:'#188A67',fontSize:'15px'}}>{translate('Redeemed')}</div>
    </Col>
</Row>



<div style={{fontSize:'15px',fontWeight:'bold'}}>Veg Premium</div>
<ul style={{fontSize:'15px'}}>
<li>Scrambled Egg with Whole Wheat Toast</li>
<li>Fresh Orange Slices</li>
<li>Milk or Fruit Juice</li>
</ul>

<div>
  <ul>
<Row>
  <Col lg={4} xl={2} md={4} sm={3} xs={3}>
    <li style={{color:'#E85C33'}}>Invalid</li></Col>
    <Col lg={4} xl={6} md={4} sm={6} xs={5}className="" style={{color:'#FA5252',fontSize:'14px'}}>Scanned Again</Col>
    <Col lg={4} xl={4}  md={4} sm={3} xs={4} className="text-end text-muted"style={{fontSize:'14px'}}>@9.30 a.m</Col></Row>
    
    <Row>
  <Col lg={4} xl={2} md={4} sm={3} xs={3}>
    <li style={{color:'#E85C33'}}>Invalid</li></Col>
    <Col lg={4} xl={6} md={4} sm={6} xs={5}className="" style={{color:'#FA5252',fontSize:'14px'}}>Scanned Again</Col>
    <Col lg={4} xl={4}  md={4} sm={3} xs={4} className="text-end text-muted"style={{fontSize:'14px'}}>@9.30 a.m</Col></Row>
    

  </ul>
</div>
</div>
          </Row> */}



            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showChangesModal}
              onHide={() => setShowChangesModal(false)}
              className=""

            >

              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none', color: '#303A5B' }} className="">{translate('Filter')}</Modal.Title>
              </Modal.Header>

              <div className="my-4 mx-3">
                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Date')}</div>


                <Row className="mb-3">

                  <Col md={6} sm={6} xs={6} lg={6} xl={6} className="position-relative">
                    <DatePicker
                      // className="customDatePickerWidth form-control border border-gray-300 text-gray-900 rounded-lg"
                      selected={fromDate}
                      // value={fromDate}
                      onChange={date => setFromDate(date)}
                      placeholderText="From"
                      showDateMonthYearPicker
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>

                  <Col md={6} sm={6} xs={6} lg={6} xl={6}>
                    <DatePicker
                      // className="customDatePickerWidth form-control border border-gray-300 text-gray-900 rounded-lg"
                      selected={todate}
                      // value={todate}
                      onChange={date => setToDate(date)}
                      placeholderText="To"

                      showDateMonthYearPicker
                      dateFormat="dd/MM/yyyy"
                    />
                  </Col>
                </Row>



                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Type')}</div>

                <Row>
                  <Col md={11} xs={11} sm={11} lg={11} xl={11}>
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Redeemed')}</div>
                  </Col>

                  <Col md={1} sm={1} xs={1} lg={1} xl={1} className="text-end">
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox1"
                      label=""
                      value='redeem'
                      onClick={handleSelectedStatus}
                      checked={isStatus.includes('redeem')}

                    />
                  </Col></Row>


                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}> {translate('Not Redeemed')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox1"
                      label=""
                      value='not-redeemed'
                      onClick={handleSelectedStatus}
                      checked={isStatus.includes('not-redeemed')}
                    />
                  </Col></Row>

                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11}>
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Invalid')}</div>
                  </Col>

                  <Col md={1} sm={1} xs={1} lg={1} xl={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox1"
                      label=""
                      value='not-valid'
                      onClick={handleSelectedStatus}
                      checked={isStatus.includes('not-valid')}
                    />
                  </Col></Row>


                <Row>
                  <Col md={6} lg={6} sm={6} xs={6} xl={6} className="text-start">
                    <button onClick={handleCloseChangesModal} className='btn btn-danger'><span className="">{translate('Clear all')}</span></button>
                  </Col>

                  <Col md={6} lg={6} sm={6} xs={6} xl={6} className="text-end" >
                    <Button onClick={async () => {
                      await fetchFilterData();
                      setShowChangesModal(false);;
                    }}
                      className="px-5 " style={{ fontColor: 'white', backgroundColor: '#E85C33', borderColor: '#E85C33' }}>
                      <span className=" "> {translate('Filter')}</span>
                    </Button>
                  </Col>
                </Row>

              </div>

            </Modal>


            <Modal show={showModal} onHide={() => setShowModal(false)}  >
              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">{translate('Add Children')}</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ fontSize: '15px' }}>

                <div className="mb-3">
                  <label htmlFor="profileUpload" className="profile-uploader">
                    <input
                      type="file"
                      id="profileUpload"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleProfileImageChange}
                    />
                    <div className="profile-image-container">
                      {profileImage ? (
                        <img src={profileImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                      ) : (
                        <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                          <img src="childprofile.png" alt="profileImage" style={{ width: '65%', height: '65%' }} />
                        </div>
                      )}

                    </div>
                  </label>
                </div>

                <Form>
                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>{translate('Name')}</Form.Label>
                    <Form.Control type="text" placeholder="Javelin Jev" />
                  </Form.Group>

                  <Form.Group controlId="formEmail" className="mb-3">
                    <Form.Label>{translate('IC No')}</Form.Label>
                    <Form.Control type="email" placeholder="84984" />
                  </Form.Group>



                  <Form.Group controlId="formDropdown1" className="mb-3">
                    <Form.Label>{translate('Grade')}</Form.Label>
                    <div className="input-group">
                      <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                        borderRadius: '8px',
                        padding: '8px',
                        border: '1px solid #ccc'

                      }}>
                        <option defaultValue>III</option>
                        <option>IV</option>
                        <option>V</option>
                        <option>VI</option>
                        <option>VII</option>
                        <option>VIII</option>


                      </select>

                    </div>
                  </Form.Group>



                  <Form.Group controlId="formDropdown1" className="mb-3">
                    <Form.Label>{translate('Section')}</Form.Label>
                    <div className="input-group">
                      <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                        borderRadius: '8px',
                        padding: '8px',
                        border: '1px solid #ccc'
                      }}>
                        <option defaultValue>A2</option>
                        <option>A1</option>
                        <option>A3</option>
                        <option>B1</option>
                        <option>B2</option>
                        <option>B3</option>


                      </select>

                    </div>
                  </Form.Group>

                  <Form.Group controlId="formName" className="mb-3">
                    <Form.Label>{translate('School')}</Form.Label>
                    <Form.Control type="text" placeholder="St.Mary Goretti Hr.Sec School" />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>

                <Button onClick={handleCloseModal} style={{
                  width: '100%',
                  backgroundColor: '#E85C33',
                  borderColor: '#E85C33',
                }}>
                  {translate('Create Child')}
                </Button>
              </Modal.Footer>
            </Modal>



            <Modal show={showMenuInfo} onHide={() => setShowMenuInfo(false)} dialogClassName="modal-dialog-centered"
              centered>

              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} >
                  {childrenData && childrenData.Menus && childrenData.Menus.length > 0 ? <>
                    {translate(childrenData.Menus[infoInd]?.MenuTypeEnglish)}
                  </> : null}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >
                <div className='mt-3'>
                  {childrenData && childrenData.Menus && childrenData.Menus.length > 0 ?
                    <div >
                      <Row>
                        <div style={{ fontSize: '17px', fontStyle: 'normal', fontWeight: 500 }}>
                          {childrenData.Menus[infoInd].CartType == 'month' ? <>

                            {translate("This menu is available from")}<p style={{ color: '#6B6B6B' }} className='d-inline'>{childrenData.Menus[infoInd].Cartfromdatetodate}</p>

                          </> : <>

                            {translate("This menu will be placed on")} {childrenData.Menus[infoInd].CartDay}'s (<p style={{ color: '#6B6B6B' }} className='d-inline'> {childrenData.Menus[infoInd].CartDates}</p>)

                          </>}

                        </div>

                      </Row>

                      {childrenData.Menus[infoInd].CartType == 'day' ?
                        <>
                          <hr />
                          {
                            Object.entries(childrenData.Menus[infoInd].CartDates)?.map(([key, value]) => {
                              return (
                                <>
                                  <Row className='mt-3'>
                                    <Col style={{ fontFamily: 'Rubik', fontSize: '17px', color: '#1A1A1A', fontWeight: 500 }}>
                                      {dayNamesMapping[key]}
                                    </Col>

                                    <Col style={{ fontFamily: 'Rubik', color: '#E85C33', fontWeight: 500 }} className='text-end'>
                                      {value}
                                    </Col>
                                  </Row>
                                </>
                              )
                            })
                          }
                          <hr />
                        </>

                        : null}

                    </div>
                    : null
                  }


                </div>

              </Modal.Body>

            </Modal>


            <Modal show={showRenewInfo} onHide={() => setShowRenewInfo(false)} dialogClassName="modal-dialog-centered"
              centered>

              <Modal.Header closeButton>
                <Modal.Title className="" style={{ fontSize: '17px' }} >
                  {translate('Renew')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="modal-body-scrollable" style={{ maxHeight: '60vh', overflowY: 'auto', overflowX: 'hidden' }} >
                <div className='mt-3'>
                  {renewData?.map((val, ind) => {
                    return (

                      <div>
                        <p style={{ fontSize: '17px' }}>{val.result}</p>
                        <p className='ms-2' style={{ fontSize: '15px' }}>{val.message}</p>
                        <hr />

                      </div>
                    )
                  })}

                  <div className='text-end'>
                    <Button style={{
                      backgroundColor: '#E85C33',
                      borderColor: '#E85C33',
                      // maxHeight: '40px'
                    }}
                      onClick={() => { renew1(id) }}
                    >Confirm</Button>
                  </div>

                </div>

              </Modal.Body>

            </Modal>

          </Container>
        </>
      )}
    </div>

  );
}


