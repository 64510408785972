import React, { useEffect, useState } from 'react'

import './custom.css'
import MyNavbar from './Navbar'
import { Container, InputGroup } from 'react-bootstrap'
import { Row } from 'react-bootstrap'
import { Col } from 'react-bootstrap'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { useTranslation } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Loader from './Loader';
import ReactPaginate from 'react-paginate'



export default function MyHistory() {
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  let navigate = useNavigate()
  const [datas, setData] = useState([])
  const [stuDetail, setStuDetail] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selecteddDate, setSelecteddDate] = useState(new Date());
  const [slctdChild, setSlctdChild] = useState([])
  const [slctStatus, setSlctStatus] = useState([])

  const dateFromObject = new Date(selectedDate);
  const dateToObject = new Date(selecteddDate);
  const fromMonth = (dateFromObject.getMonth() + 1).toString().padStart(2, '0');
  const fromYear = dateFromObject.getFullYear();
  const fromDate = `${fromYear}-${fromMonth}`;

  const { selectedLanguage } = useTranslation();

  const toMonth = (dateToObject.getMonth() + 1).toString().padStart(2, '0');
  const toYear = dateToObject.getFullYear();
  const toDate = `${toYear}-${toMonth}`;
  console.log("change date", fromDate, "-", toDate)

  const [isChecked, setIsChecked] = useState([])
  const [isStatus, setIsStatus] = useState([])
  const [noChld, setNoChild] = useState(false)
  console.log(isStatus, "stat")
  const dayNamesMapping = {
    Sun: 'Sunday',
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday'
  };

  const handleChildId = (childId) => {

    if (slctdChild.includes(childId)) {
      setSlctdChild(prevStatus => prevStatus.filter(item => item !== childId));
      setIsChecked(prevStatus => prevStatus.filter(item => item !== childId));
    } else {
      setSlctdChild(prevStatus => [...prevStatus, childId]);
      setIsChecked(prevStatus => [...prevStatus, childId]);
    }
  }
  const handlePageClick = () => {

  }

  const listDetails = async () => {
    const parentIDString = localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');
    const childrenId = slctdChild.join(',')
    const childrenStatus = slctStatus.join(',')
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ChildrenID', childrenId)
      bodyFormData.append('ParentID', parentIDString)
      bodyFormData.append('ParentSchoolID', storedValue)
      bodyFormData.append('PaymentStatus', childrenStatus)
      bodyFormData.append('OrderDateFrom', fromDate)
      bodyFormData.append('OrderDateTo', toDate)
      console.log(bodyFormData.values, "formdata")
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listorderhistory',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            console.log(res.data.result, 'test data');
            setData(res.data.result);
            getChildrenDetails(res.data.result.SchoolID)
            setNoChild(false)
          }
          else {
            setData([])
            setLoading(false)
            setNoChild(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)

        });
    }
    setShowChangesModal(false)
  }
  const getdetails = async () => {
    setLoading(true)
    const parentIDString = localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');

    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ParentID', parentIDString)
      bodyFormData.append('ParentSchoolID', storedValue)

      console.log(bodyFormData.values, "formdata")
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listorderhistory',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            console.log(res.data.result, 'test data');
            setData(res.data.result);
            getChildrenDetails(res.data.result.SchoolID)
            setNoChild(false)

          }
          else {
            setLoading(false)
            setNoChild(true)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          setLoading(false)

        });
    } else {
      navigate('/welcome')
    }
  }
  useEffect(() => {
    getdetails();
    getChildrenDetails();
  }, [])
  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const [profileImage, setProfileImage] = useState(null);


  const handleProfileImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfileImage(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showChangesModal, setShowChangesModal] = useState(false);
  const handleClearAll = () => {
    setSelectedDate(new Date())
    setSelecteddDate(new Date())
    setSlctdChild([])
    setSlctStatus([])
    setIsChecked([])
    setIsStatus([])
    getdetails();
    getChildrenDetails();
  }
  const handleStatus = (e) => {
    const status = e.target.value
    if (slctStatus.includes(status)) {
      setSlctStatus(prevStatus => prevStatus.filter(item => item !== status));
      setIsStatus(prevStatus => prevStatus.filter(item => item !== status));
    } else {
      // If val is not in slctdChild array, add it
      setSlctStatus(prevStatus => [...prevStatus, status]);
      setIsStatus(prevStatus => [...prevStatus, status]);
    }

  }
  const handleCloseModal = () => {
    setShowChangesModal(false);
  };



  const boxStyle = {

    backgroundColor: '#EFEFEF',
    borderRadius: '12px',
    padding: '12px'


  };

  const schoolName = {
    fontSize: "15px"
  };
  const placeColor = {
    fontSize: "15px"
  };
  const buttoncolor = {
    backgroundColor: '#F17C38',
    border: 'none'
  }

  const getChildrenDetails = async () => {
    const parentIDString = localStorage.getItem('hnhpapauserId');
    const schoolId = localStorage.getItem('hnhpapaSchoolId');
    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ParentID', parentIDString)
      bodyFormData.append('ParentSchoolID', schoolId)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/listchildrens',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setStuDetail(res.data.result)
            setLoading(false)
          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
          // setLoading(false)

        });
    } else {
      navigate('/welcome')
    }
  }
  console.log(stuDetail, "dff")

  return (
    <div>
      {loading && stuDetail.length == 0 ? (
        <Loader />
      ) : (
        <>


          <Container>
            <MyNavbar />


            <div className="my-4">

              <Row className=" ">
                <Col xs={5} sm={7} md={5} lg={3} xl={3}>
                  <Link to="/" className="link-no-underline">
                    <img src="leftarrow.png" alt="School Logo" className="pe-1" /><span className="" style={{ fontSize: '15px' }}>{translate('Order History')}</span>
                  </Link>
                </Col>
                <Col className='text-end me-2'>
                  <img onClick={() => setShowChangesModal(true)} src={process.env.PUBLIC_URL + '/filter_list.svg'} />
                </Col>

              </Row>
            </div>


            {datas && datas.length > 0 ? (
              datas.map((item, index) => (
                <div>
                  <Row className="mx-1">

                    <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                      <Link to={`/payment/${item.OrderID}`} className="link-no-underline">
                        <Row className="flex align-items-center mb-3">
                          <Col xs={2} sm={2} md={2} lg={1} xl={1} className="text-end">
                            {/* <img src="childprofile.png" alt="School Logo" /> */}
                            <img src={Image_Url + item.ChildrenImage} alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                          </Col>
                          <Col xs={4} sm={3} md={4} lg={5} xl={9} className="">
                            <div style={schoolName} className="font-weight-bold text-black ">{item.ChildrenName}</div>
                            <div style={placeColor} className="placecolor  text-muted  color-white ">{item.ChildrenGrade}</div>
                          </Col>

                          <Col xs={6} sm={7} md={6} lg={6} xl={2} className="">
                            <div className="text-end " style={{ fontSize: '15px', color: item.OrderStatus == 'Completed' ? '#1EAC81' : item.OrderStatus == 'Pending' ? '#E85C33' : '#FAB005' }}>{translate('Order')} {item.OrderStatus == 'Pending' && selectedLanguage == 'en' ? 'Incomplete' : translate(item.OrderStatus)}</div>
                            {/* <div className="text-end text-muted" style={{ fontSize: '14px' }}>{item.OrderDate}</div> */}
                          </Col>

                        </Row>
                        <Row>


                          {item.Menus && item.Menus.length > 0 ? (
                            item.Menus.map((item1, index1) => (
                              <div >

                                <Row >

                                  <Col md={9} lg={9} xl={10} sm={9} xs={8} >
                                    <ul className="text-start" style={{ fontSize: '14px', color: '#333' }}>
                                      {selectedLanguage === 'en' && (
                                        <>

                                          {item1.MenuTittleEnglish}-{item1.MenuTypeEnglish}
                                        </>

                                      )}
                                      {selectedLanguage === 'es' && (
                                        <>
                                          {item1.MenuTittleChinese}-{item1.MenuTypeChinese}
                                        </>

                                      )}
                                      {/* {item1.MenuTittleEnglish}-{item1.MenuTypeEnglish} */}
                                      {/* <p className="text-start ms-2" style={{ fontSize: '12px', color: '#7C7C7C' }}>

                                        {item1.CartType == 'month' ? `${item1.CartMonth}(All Working Days)` :
                                        <>
                                      
                                         { item1.CartMonth}
                                        (  {item1.CartType } :
                                          {Object.entries(item1.CartDates)?.map(([key, value]) => {
                                          return (dayNamesMapping[key])
                                        })

                                          })
                                        </> }
                                      </p> */}
                                      <p className="text-start ms-2" style={{ fontSize: '12px', color: '#7C7C7C' }}>

                                        {item1.CartType == 'month' ? <>
                                          {translate("This menu is available from")} <p style={{ color: '#333' }} className='d-inline'>{item1.Cartfromdatetodate}</p>
                                        </> :
                                          <>

                                            {translate("This menu is available on")} {item1.CartDay}'s (<p style={{ color: '#333' }} className='d-inline'> {item1.CartDates}</p>)

                                          </>
                                        }
                                      </p>
                                    </ul>

                                  </Col>

                                </Row>

                              </div>


                            ))
                          ) : (
                            !loading && !item.Menus ?

                              <p style={{ color: '#E85C33' }}>{translate('No Order History Found')}</p> : null

                          )}


                          {/* <Col md={9} lg={9} xl={10} sm={9} xs={8} className="mt-2">
                                          <ul className="text-start" style={{fontSize:'14px',color:'#7C7C7C'}}>{item1.MenuTittleEnglish}
                                          </ul>{type}
                                          </Col>
                                          <Col md={3} lg={3} xl={2} sm={3} xs={4}className="justify-end align-items-end">
                                          <div className="mt-5 text-end"style={{fontSize:'15px',color:'#303A5B'}}>{item1.MenuAmount}</div>
                                          </Col> */}

                          <Col className='text-end'>
                            RM {item.totalAmount}
                          </Col>
                        </Row>
                      </Link>

                    </div>

                  </Row>

                </div>
              ))
            ) :
              (
                !loading && noChld ?

                  <Col lg={12} xl={6} md={12}>
                    <Row className="mx-1">
                      <div style={boxStyle} className="border border-gray-100 my-1  py-3 px-3">
                        <p style={{ color: '#E85C33' }}>{translate('No Order History Found')}</p>
                      </div>
                    </Row>
                  </Col>
                  : null
              )
            }

            <Modal dialogClassName="modal-dialog-centered"
              centered
              show={showChangesModal}
              onHide={() => setShowChangesModal(false)}
              className=""

            >

              <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: '16px', border: 'none', color: '#303A5B' }} className="">{translate('Filter')}</Modal.Title>
              </Modal.Header>
              <div>
                <div className="my-3 ms-2" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Children')}</div>
                {stuDetail.map((val, ind) => {
                  return (
                    <div key={ind} style={boxStyle} className="mx-2 mb-2 border border-gray-100 my-1 ">

                      <Row className="flex align-items-center">
                        <Col xs={2} sm={3} md={3} lg={3} xl={1} className="text-end">
                          <img src={Image_Url + val.ChildrenImage} alt="School Logo" style={{ width: '44px', height: '44px', borderRadius: '44px' }} />
                        </Col>
                        <Col xs={7} sm={6} md={6} lg={6} xl={8} className="text-start">
                          <div style={schoolName} className="font-weight-bold text-black ms-2">{val.ChildrenName}</div>
                          <div style={placeColor} className="placecolor  text-muted  color-white  ms-2 "> {val.ChildrenGrade}</div>
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3}>

                          <div className='text-end ms-5'>
                            {/* <input type='checkbox' /> */}
                            <input
                              type="checkbox"
                              className='order-check-form '
                              id={val.ChildrenID}
                              onClick={() => handleChildId(val.ChildrenID)}
                              checked={isChecked.includes(val.ChildrenID)}
                              label={<label className="custom-checkbox-label"></label>}
                            />

                          </div>
                        </Col>
                      </Row>
                    </div>
                  )

                })}
              </div>




              <div className="my-4 mx-3">
                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>
                  {translate('Date')}
                </div>

                <Row className="">
                  <Col sm={3} xs={3} md={3} lg={3} xl={4} className="pe-2 mb-3">
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      placeholderText="From"
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      className="custom-date-picker "
                      style={{ width: '20px' }}
                    />
                  </Col>
                  <Col xs={3} sm={3} md={3} lg={3} xl={4} className="pe-1"></Col>
                  <Col sm={6} xs={6} md={6} lg={6} xl={4} className="text-end">
                    <DatePicker
                      selected={selecteddDate}
                      onChange={(date) => setSelecteddDate(date)}
                      placeholderText="To"
                      showMonthYearPicker
                      dateFormat="MM/yyyy"
                      className="custom-date-picker"
                      style={{ width: '80px' }}
                    />
                  </Col>
                </Row>



                <div className="mb-3" style={{ fontWeight: 'bold', color: '#303A5B' }}>{translate('Type')}</div>

                <Row>
                  <Col md={11} xs={11} sm={11} lg={11} xl={11}>
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Order Success')}</div>
                  </Col>

                  <Col md={1} sm={1} xs={1} lg={1} xl={1} className="text-end">
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox1"
                      label=""
                      value='Completed'
                      async onClick={handleStatus}
                      checked={isStatus.includes('Completed')}

                    />
                  </Col></Row>


                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Order Processing')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox2"
                      label=""
                      value='Pending'
                      onClick={handleStatus}
                      checked={isStatus.includes('Pending')}

                    />
                  </Col></Row>
                <Row>
                  <Col md={11} xs={11} sm={11} xl={11} lg={11} className="justify-end">
                    <div className="mb-3" style={{ color: '#6B6B6B' }}>{translate('Cancelled')}</div>
                  </Col>

                  <Col md={1} sm={1} lg={1} xl={1} xs={1}>
                    <input
                      type="checkbox"
                      className='order-check-form '
                      id="checkbox3"
                      label=""
                      value='Failed'
                      onClick={handleStatus}
                      checked={isStatus.includes('Failed')}

                    />
                  </Col></Row>



                {/* <Row>
              <Col md={11} xs={11} sm={11} xl={11} lg={11}>
                <div className="mb-3" style={{ color: '#6B6B6B' }}>Expired</div>
              </Col>

              <Col md={1} sm={1} xs={1} lg={1} xl={1}>
                <Form.Check
                  type="checkbox"
                  id="checkbox1"
                  label=""
                />
              </Col></Row> */}







                <Row>
                  <Col md={6} lg={6} sm={6} xs={6} xl={6} className="text-start">
                    <button onClick={handleClearAll} className='btn btn-danger'><span className="">{translate('Clear all')}</span></button>
                  </Col>

                  <Col md={6} lg={6} sm={6} xs={6} xl={6} className="text-end" >
                    <Button onClick={listDetails} className="px-5 " style={{ fontColor: 'white', backgroundColor: '#E85C33', borderColor: '#E85C33' }}>
                      <span className=" "> {translate('Filter')}</span>
                    </Button>
                  </Col>
                </Row>


              </div>


            </Modal>

            {/* <Modal show={showModal} onHide={handleCloseModal}  >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: '16px', border: 'none' }} className="">Add Children</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: '15px' }}>

            <div className="mb-3">
              <label htmlFor="profileUpload" className="profile-uploader">
                <input
                  type="file"
                  id="profileUpload"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={handleProfileImageChange}
                />
                <div className="profile-image-container">
                  {profileImage ? (
                    <img src={profileImage} alt="Profile" className="profile-image rounded-circle" style={{ width: '65px', height: '65px' }} />
                  ) : (
                    <div className="profile-placeholder rounded-circle" style={{ width: '65px', height: '65px' }}>
                      <img src="childprofile.png" alt="profileImage" style={{ width: '65%', height: '65%' }} />
                    </div>
                  )}

                </div>
              </label>
            </div>

            <Form>
              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Javelin Jev" />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>IC No</Form.Label>
                <Form.Control type="email" placeholder="84984" />
              </Form.Group>



              <Form.Group controlId="formDropdown1" className="mb-3">
                <Form.Label>Grade</Form.Label>
                <div className="input-group">
                  <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                    borderRadius: '8px',
                    padding: '8px',
                    border: '1px solid #ccc'

                  }}>
                    <option defaultValue>III</option>
                    <option>IV</option>
                    <option>V</option>
                    <option>VI</option>
                    <option>VII</option>
                    <option>VIII</option>


                  </select>

                </div>
              </Form.Group>



              <Form.Group controlId="formDropdown1" className="mb-3">
                <Form.Label>Section</Form.Label>
                <div className="input-group">
                  <select className=" custom-select w-100  bg-white border-gray-800" id="inputGroupSelect01" style={{
                    borderRadius: '8px',
                    padding: '8px',
                    border: '1px solid #ccc'
                  }}>
                    <option defaultValue>A2</option>
                    <option>A1</option>
                    <option>A3</option>
                    <option>B1</option>
                    <option>B2</option>
                    <option>B3</option>


                  </select>

                </div>
              </Form.Group>

              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>School</Form.Label>
                <Form.Control type="text" placeholder="St.Mary Goretti Hr.Sec School" />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>

            <Button onClick={handleCloseModal} style={{
              width: '100%',
              backgroundColor: '#E85C33',
              borderColor: '#E85C33',
            }}>
              Create Child
            </Button>
          </Modal.Footer>
        </Modal> */}

            {pageCount > 1 ?
              <div className='my-5 d-flex justify-content-center'>
                <ReactPaginate
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  activeClassName="active"
                  disabledClassName="disabled"
                />
              </div> : null}



          </Container>
        </>
      )}
    </div>

  );
}