import React, { useEffect, useState } from 'react'
import './custom.css';
import MyNavbar from './Navbar';
import { Container, Row, Col, Modal, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from './LanguageContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Base_Url } from './globalvariable/globe';
import { Image_Url } from './globalvariable/globe';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from 'react-bootstrap/Spinner';

import { ToastContainer, toast } from 'react-toastify'


export default function MyTerms() {
  const { translate, changeLanguage } = useTranslation();
  const [message, setMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showCreateChildModal, setShowCreateChildModal] = useState(false);
  const [showConfirmLogoutModal, setShowConfirmLogoutModal] = useState(false);
  const [manage, setManage] = useState('terms')
  const [notifCount, setNotifCout] = useState(0);

  const schoolName = {
    fontSize: '15px',
  };

  const placeColor = {
    fontSize: '15px',
  };

  const handleConfirmLogout = () => {
    setShowModal(false);

    setShowConfirmLogoutModal(true);
  };

  let navigate = useNavigate()
  const [datas, setData] = useState([])


  const getdetails = async () => {
    const parentIDString = await localStorage.getItem('hnhpapauserId');
    const storedValue = localStorage.getItem('hnhpapaSchoolId');
// console.log

    if (parentIDString) {
      let bodyFormData = new FormData()
      bodyFormData.append('ParentID', parentIDString)
      bodyFormData.append('ParentSchoolID', storedValue)
      let responce = await axios({
        method: 'post',
        url: Base_Url + '/terms',
        data: bodyFormData,
        dataType: 'json',
        headers: { "Content-Type": "multipart/form-data" }
      })
        .then(async (res) => {
          if (res.data.status === true) {
            setData(res.data.result.TermDetails);
            setNotifCout(res.data.notification)

          }
        })
        .catch((err) => {
          console.log('Error fetching data:', err);
        });
    } else {
      navigate('/welcome')
    }
  }
  useEffect(() => {
    getdetails();
  }, [])



  const handleConfirmLogoutHide = () => {
    setShowConfirmLogoutModal(false);
  }


  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }


  const logout = () => {
    localStorage.removeItem('hnhpapauserId');
    localStorage.removeItem('hnhpapaSchoolId');
    localStorage.removeItem('hnhpapaemail');
    localStorage.removeItem('hnhpapaschoolName');
    navigate('/welcome');
  }






  return (
    <Container>
      <MyNavbar />

      <div className="my-4">
        <Row className=" ">
          <Col xs={5} sm={7} md={5} lg={3} xl={3}>

            <Link to="/" className="link-no-underline">
              <img src="leftarrow.png" alt="School Logo" className="pe-2" />
              {translate('Terms')} & {translate('Conditions')}
            </Link>
          </Col>
        </Row>
      </div>
      <Row>
        <Col lg={3} xl={3} md={12} sm={12} xs={12}>

          <div className="my-3">
            <Link to="/account" className="link-no-underline pe-2" ><img src="pro.png" alt="Profile" className="pe-2" />
              <span  >{translate('Profile')}</span></Link>

          </div>
          <div >

            <Link to="/terms" className="link-no-underline">
              <div onClick={() => { setManage("terms") }} className={manage == 'terms' ? "border border-gray-100 ps-1  py-2" : 'mb-5 mt-3' }
                style={manage === 'terms' ? { background: 'rgb(239, 239, 239)', borderRadius: '8px' } : {}} >
                <img src="terms.png" alt="Terms & Conditions" className="pe-2" />  {translate('Terms')} & 
                {translate('Conditions')}
              </div>
            </Link>
          </div>
          <div>
            <Link to="/notifications" className="link-no-underline pe-2" >
              <img src="notify.png" alt="Notification" className="pe-2" />
              <span onClick={() => { setManage("notification") }} className={manage == 'notification' ? "border border-gray-100 ps-2 py-2" : 'my-4'}
                style={manage === 'notification' ? { background: 'rgb(239, 239, 239)', borderRadius: '8px' } : {}} >
                {translate('Notification')}
              </span>
              {notifCount > 0 ? <span style={{
                marginTop: '-35px', display: 'block', marginLeft: '-11px', color: 'white', padding: '-11px',
                borderRadius: '24px', textAlign: 'center', background: 'red', width: '16px', height: '18px', fontSize: '12px'
              }}>
                !
              </span> : null}
            </Link>
          </div>

          <div className="my-4">
            <Link to="/history" className="link-no-underline">   <img src="terms.png" alt="Logout" className="pe-2" />
              {translate('Order History')}
            </Link>
          </div>

          <div className="my-4">
            <Link className="link-no-underline" onClick={handleConfirmLogout}>   <img src="logout.png" alt="Logout" className="pe-2" />         {translate('Logout')}
            </Link>
          </div>

        </Col>
        <Col lg={9} xl={9} md={12} sm={12} xs={12} className="">


          <div dangerouslySetInnerHTML={{ __html: datas }} />

        </Col>

      </Row>


      <Modal dialogClassName="modal-dialog-centered"
        centered
        show={showConfirmLogoutModal}
        onHide={() => setShowConfirmLogoutModal(false)}

      >

        <div className="my-4">
          <div className="text-center my-3" style={{ color: '#6B6B6B' }}>Are You Sure, You want to Logout !</div>
          <Row>
            <Col xl={6} lg={6} md={6}><Button style={{ background: 'var(--layout-100, #F9F9F9)', color: 'black', border: 'unset', float: 'right' }} onClick={handleConfirmLogoutHide}>Cancel</Button></Col>
            <Col xl={6} lg={6} md={6}><Link to="/welcome"> <Button style={{ background: 'var(--buttons-red-700, #C84242)', color: 'white', border: 'unset' }} onClick={logout} >Logout</Button></Link></Col>
          </Row>
        </div>


      </Modal>

    </Container>
  );
}
