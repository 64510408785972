import React, { useState, useRef } from 'react';
import { useTranslation } from './LanguageContext';

const MyOtpfield= ({ onOtpSubmit, otpError, otpErrorMsg, btnLoader }) => {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    const inputValue = e.target.value;
    const formattedValue = inputValue.replace(/\D/g, '');
    newOtp[index] = formattedValue;
    setOtp(newOtp);

    if (e.target.value !== '' && index < inputRefs.current.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && otp[index] === '' && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handleOtpSubmit = () => {
    const otpValue = otp.join(''); // Convert the OTP array to a string
    onOtpSubmit(otpValue); // Pass the OTP value to the parent component
  };

  return (
    <>
    <div className='mb-10'>
    <div className="d-flex ">
      {otp.map((digit, index) => (
        <input
          key={index}
          type="text"
          id={`otp${index + 1}`}
          className={`${otpError ? "bg-red-50 !border-red-600" : " border border-gray-300"} ${"otp-input pl-10 text-red-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-10 p-2.5"}`}style={{width:"25%",padding:"10px",margin:"5px",borderRadius:"8px", borderColor:'#E85C33'}}
          // className="otp-input pl-10 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          maxLength={1}
          value={digit}
          onChange={(e) => handleOtpChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          ref={(el) => (inputRefs.current[index] = el)}
        />
      ))}
      
    </div>
    {otpErrorMsg && <div className="text-red-600 text-xs mt-2">{otpErrorMsg}</div>}
    </div>
   
    </>
  );
};

export default MyOtpfield;
