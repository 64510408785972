import React from "react";
import Spinner from 'react-bootstrap/Spinner';


const Loaderbtn = () => {
  return (
    <button
      disabled=""
      type="button"
      style={{
        width: '100%',
        backgroundColor: '#E85C33', 
        borderColor: '#E85C33',     
      }}
    >
       <Spinner animation="border" role="status">
    </Spinner>
    </button>
  );
};

export default Loaderbtn;
