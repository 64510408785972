import React, { useState, useEffect } from 'react';
import './custom.css';
import MyNavbar from './Navbar';
import NavbarLogin from './Navbarlogin';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Base_Url } from './globalvariable/globe';
import { useTranslation } from './LanguageContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import Loaderbtn from './Loaderbtn';
import Spinner from 'react-bootstrap/Spinner';



export default function MyRegister() {



  const { translate, changeLanguage } = useTranslation();

  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
  }
  const schools = () => {
    alert('Please verify school code')
  }

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisiblee, setPasswordVisiblee] = useState(false);
  const [button, SetButton] = useState(false);
  const[isAllVerified,setIsAllVerified]=useState(0)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const [name, setname] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNo, setphoneNo] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setconfirmPassword] = useState('')
  const [school, setSchool] = useState('')
  const [school1, setSchool1] = useState('')
  const [schoolDetails, setSchoolDetails] = useState([])

  const [verifyname, setVerifyname] = useState(true)
  const [verifyemail, setVerifyEmail] = useState(true)
  const [verifyphoneNo, seVerifytphoneNo] = useState(true)
  const [verifypassword, setVerifyPassword] = useState(true)
  const [verifypassword1, setVerifyPassword1] = useState('')
  const [verifyconfirmPassword, setVerifyconfirmPassword] = useState(true)
  const [verifyschool, setVerifySchool] = useState(true)
  const [verifyemailregx, setVerifyEmailRegx] = useState(true)
  const [verifyphoneNoStatus, setVerifyphoneNoStatus] = useState(true)
  const [passwordvalid1, passwordvalid] = useState(true)
  const [invalidCredentails, valid] = useState(true)
  const [invalidvalue, validvalue] = useState('')
  const [disableButton, setDisableButton] = useState(true)
  const [schoolName, setSchoolName] = useState(true)

  const togglePasswordVisibilityy = () => {
    setPasswordVisiblee(!passwordVisiblee);
  };
  const successToast = (e) => {
    toast.dismiss();
    toast.success(e);
  };
  const errorToast = (e) => {
    toast.dismiss();
    toast.error(e);
  };
  const checkname = (e) => {

    const onlyLettersRegex = /^[A-Za-z\s]+$/;

    const newValue = e.target.value;

    if (onlyLettersRegex.test(newValue) || newValue === '') {
      setname(e.target.value);
      setVerifyname(true);
    }
    // setname(e.target.value);
    // setVerifyname(true);
    // valid(true)
  }
  const checkEmail = (e) => {
    setEmail(e.target.value);
    setVerifyEmail(true);
    setVerifyEmailRegx(true)
    // valid(true)
  }
  const checkphoneNo = (e) => {
    // setphoneNo(e.target.value);

    setphoneNo(e.target.value.replace(/[^0-9]/g, "").slice(0, 15))
    if (phoneNo.length >= 10) {
      return
    }
    seVerifytphoneNo(true);
    setVerifyphoneNoStatus(true)
    // valid(true)
  }
  const checkPassword = (e) => {
    if (password.length >= 20) {
      return
    }
    setPassword(e.target.value);
    setVerifyPassword(true);
    setVerifyPassword1('');
    // valid(true)
  }
  const checkconfirmPassword = (e) => {
    if (confirmPassword.length >= 20) {
      return
    }
    setconfirmPassword(e.target.value);
    setVerifyconfirmPassword(true);
    // valid(true)
  }
  const checkSchool = (e) => {
    setSchool(e.target.value);
    setVerifySchool(true);
    setDisableButton(true)
    // valid(true)
  }
  let navigate = useNavigate()
  const register = async () => {

    if (email != "") {
      let emailveri = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (email.match(emailveri)) {
        setVerifyEmailRegx(true)
      } else {
        setVerifyEmailRegx(false)

      }
    }
    if (phoneNo != "") {
      if (phoneNo.length < 10 || phoneNo == '0000000000') {
        setVerifyphoneNoStatus(false)
        seVerifytphoneNo(true);

      } else {
        setVerifyphoneNoStatus(true)
      }
    }
    if (password.length < 6) {
      setVerifyPassword(false);
      setVerifyPassword1('Enter Min 6 Character');
    } else {
      if (password != confirmPassword) {
        passwordvalid(false)

        errorToast(`${translate('Password & Confirm Password Mismatch')}`);

        return false;

      } else {
        passwordvalid(true)
      }
    }
    if (name == "" || email == "" || phoneNo == "" || password == "" || confirmPassword == "" || school == "" || phoneNo.length < 10 || phoneNo == '0000000000' || password.length < 6 ) {
      if (name == "") {
        setVerifyname(false);

      }
      if (email == "") {
        setVerifyEmail(false);
        setVerifyEmailRegx(true)
      }
      if (phoneNo == "") {
        seVerifytphoneNo(false);
        setVerifyphoneNoStatus(true)
      }
      if (password == "") {
        setVerifyPassword(false);
        setVerifyPassword1('Enter Password');

      } else if (password.length < 6) {
        setVerifyPassword(false);
        setVerifyPassword1('Enter Min 6 Character');
      }
      if (confirmPassword == "") {
        setVerifyconfirmPassword(false);
      }
      if (school == "") {
        setVerifySchool(false);
      }
      
      return false;
    }
    if(disableButton == true)
      {
        alert(`${translate('Please Verify School Code')} `);
        return false;
      }


    SetButton(true);
    let response = await axios({
      method: 'post',
      url: `${Base_Url}/registeration`,
      data: {
        ParentName: name,
        ParentEmail: email,
        ParentNumber: phoneNo,
        ParentPassword: password,
        ParentSchoolID: school1,
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          localStorage.setItem('hnhpapaemail', email);
          successToast(`${translate('Registered Successfully')} `);


          setTimeout(() => {
            navigate('/otpform?key=register')
          }, 1000);
          SetButton(false);

        } else {
          valid(false)
          // validvalue(res.data.errors)
          errorToast(res.data.errors);
          SetButton(false);
        }
      }).catch((error) => {
        console.error('Error', error)
        SetButton(false);
      })

  }
  const detail = async () => {
    let response = await axios({
      method: 'post',
      url: `${Base_Url}/allschools`,
      data: {
      }
    })
      .then((res) => {
        console.log('response', res);
        if (res.data.status == true) {
          setSchoolDetails(res.data.result)
        } else {
        }
      }).catch((error) => {
        console.error('Error', error)
      })

  }
  const verifyCode = async () => {

    if (school == "") {
      setVerifySchool(false);
    } else {

      let response = await axios({
        method: 'post',
        url: `${Base_Url}/schoolcode`,
        data: {
          SchoolCode: school,
        }
      })
        .then((res) => {
          console.log('response', res);
          if (res.data.status == true) {
            successToast(res.data.message)
            setDisableButton(false)
            setSchool1(res.data.result.SchoolID)
            setSchoolName(res.data.result.SchoolNameEnglish)
          } else {
            errorToast(res.data.message)
            setDisableButton(true)
            setSchool1('')
            setSchoolName('')
          }
        }).catch((error) => {
          console.error('Error', error)
          SetButton(false)
        })
    }
  }


  useEffect(() => {
    detail();
  }, [])

  return (
    
    <>
      <ToastContainer />
      <NavbarLogin />

      <Container>

        <Container className="">
          <Row className="justify-content-center ">
            <Col md={6} lg={4} className="justify-content-center align-items-center mt-3">
                {/* <Link to="/welcome">  <img src="leftarrow.png" className="pb-3"/></Link> */}
                <div style={{ borderRadius: '10px', backgroundColor: '#EFEFEF' }}className=" border border-gray-100 p-4 rounded mb-5">

                <Form>
                  <div style={{ fontSize: '18px' }} className="fw-bold pb-3">
                    {translate('Hello')}!<span className="ps-2">{translate('Registration to get Started')}</span>
                  </div>


                  <Form.Group className="mt-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontSize: '15px' }}>{translate('Full Name')} </Form.Label>
                    <Form.Control
                      className={(verifyname ? false : 'errorField')}
                      style={{ padding: '10px', borderRadius: '9px', fontSize: '15px' }}
                      type="text"
                      placeholder={translate('Enter Full Name')}

                      onChange={(e) => checkname(e)}
                      value={name}
                    />
                  </Form.Group>
                  {
                    verifyname ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Full Name')}</p>
                  }

                  <Form.Group className="mt-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontSize: '15px' }}>{translate('Email')} </Form.Label>
                    <Form.Control
                      className={(verifyemail ? false : 'errorField', verifyemailregx ? false : 'errorField')}
                      style={{ padding: '10px', borderRadius: '9px', fontSize: '15px' }}
                      type="email"
                      placeholder={translate('Enter your Email')}

                      onChange={(e) => checkEmail(e)}
                      value={email}
                    />
                  </Form.Group>
                  {
                    verifyemail ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Email')}</p>
                  }
                  {
                    verifyemailregx ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Valid Email')}</p>
                  }

                  <Form.Group className="mt-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontSize: '15px' }}>{translate('Phone No')} </Form.Label>
                    <Form.Control
                      className={(verifyphoneNo ? false : 'errorField', verifyphoneNoStatus ? false : 'errorField')}
                      style={{ padding: '10px', borderRadius: '9px', fontSize: '15px' }}
                      // type="number"
                      placeholder={translate('Enter Phone No')}

                      onChange={(e) => checkphoneNo(e)}
                      value={phoneNo}
                    />
                  </Form.Group>
                  {
                    verifyphoneNo ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Phone No')}</p>
                  }
                  {
                    verifyphoneNoStatus ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Valid Phone Number')}</p>
                  }


                  <Form.Group controlId="formBasicPassword">
                    <Form.Label className="mt-3" style={{ fontSize: '15px' }}>{translate('Password')}</Form.Label>
                    <div style={{ position: 'relative' }}>
                      <Form.Control
                        className={(verifypassword ? false : 'errorField')}
                        type={passwordVisible ? 'text' : 'password'}
                        placeholder={translate('Enter Password')}
                        style={{ padding: '10px', borderRadius: '9px', fontSize: '15px' }}
                        onChange={(e) => checkPassword(e)}
                        value={password}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          right: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        {passwordVisible ? (
                          <svg style={{ marginRight: '3px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        ) : (

                          <img src="eye.png" alt="Hide Password" />

                        )}
                      </div>
                    </div>
                    {
                      verifypassword ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate(verifypassword1)}</p>
                    }
                  </Form.Group>


                  <Form.Group controlId="formBasicPassword">
                    <Form.Label className="mt-3" style={{ fontSize: '15px' }}>{translate('Confirm Password')}</Form.Label>
                    <div style={{ position: 'relative' }}>
                      <Form.Control
                        className={(verifyconfirmPassword ? false : 'errorField')}
                        type={passwordVisiblee ? 'text' : 'password'}
                        placeholder={translate('Enter Confirm Password')}

                        style={{ padding: '10px', borderRadius: '9px', fontSize: '15px' }}
                        onChange={(e) => checkconfirmPassword(e)}
                        value={confirmPassword}
                      />
                      <div
                        style={{
                          position: 'absolute',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          right: '10px',
                          cursor: 'pointer',
                        }}
                        onClick={togglePasswordVisibilityy}
                      >
                        {passwordVisiblee ? (
                          <svg style={{ marginRight: '3px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash-fill" viewBox="0 0 16 16">
                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                          </svg>
                        ) : (
                          <img src="eye.png" alt="Hide Password" />

                        )}
                      </div>
                    </div>
                  </Form.Group>
                  {
                    verifyconfirmPassword ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter Confirm Password')}</p>
                  }

                  <Form.Group className="mt-3" controlId="formBasicEmail">
                    <Form.Label style={{ fontSize: '15px' }}>{translate('School Code')} </Form.Label>
                    <Row>
                      <Col xl={10} lg={10} md={10} sm={10} xs={10}>
                        <Form.Control
                          className={(verifyschool ? false : 'errorField')}
                          style={{ padding: '10px', borderRadius: '9px', fontSize: '15px' }}
                          type="text"
                          placeholder={translate('Enter School Code')}

                          onChange={(e) => checkSchool(e)}
                          value={school}
                        />
                      </Col>
                      <Col xl={2} lg={2} md={2} sm={2} xs={2}>
                        <button type="button" class="btn btn-success" style={{ marginLeft: '-17px', marginTop: '3px', width: '69px', height: '40px' }} onClick={verifyCode}>
                          {translate('verify')}
                        </button>
                        
                      </Col>

                    </Row>
                  </Form.Group>
                  {
                    verifyschool ? false : <p style={{ color: 'red', fontSize: '12px', marginTop: '5px' }}>{translate('Enter School Code')}</p>
                  }

                  
                  {disableButton == false ? (
                    <Form.Group className="mt-3" controlId="formBasicEmail">
                      <Form.Label style={{ fontSize: '15px' }}>{translate('School Name')} </Form.Label>
                      <Form.Control
                        style={{
                          padding: '10px', borderRadius: '9px', fontSize: '15px', border: '1.4px solid var(--layout-300, #E1E1E1)',
                          background: 'var(--layout-100, #F9F9F9)'
                        }}
                        type="text"
                        placeholder=""
                        value={schoolName}
                      />
                    </Form.Group>

                  ) : (<></>)}
                  {/* {disableButton == true ? (

                    <Button
                      className="fw-bold mt-4  text-white block-cursor"
                      style={{
                        width: '100%',
                        backgroundColor: '#E85C33',
                        borderColor: '#E85C33',
                        color: 'white',
                        padding: '10px',
                        borderRadius: '9px',
                      }}
                      onClick={schools}
                    >
                      Register
                    </Button>


                  ) : ( */}

                    {button == true ? (
                      <Button
                        className="fw-bold mt-4  text-white"
                        style={{
                          width: '100%',
                          backgroundColor: '#E85C33',
                          borderColor: '#E85C33',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '9px',
                        }}
                      >
                        <Spinner animation="border" role="status">
                        </Spinner>
                      </Button>
                    ) : (
                      <Button
                        className="fw-bold mt-4  text-white"
                        style={{
                          width: '100%',
                          backgroundColor: '#E85C33',
                          borderColor: '#E85C33',
                          color: 'white',
                          padding: '10px',
                          borderRadius: '9px',
                        }}
                        onClick={register}
                      >
                        {translate('Register')}
                      </Button>
                    )}

                  <div className="text-center mt-4">
                    {translate('Already have an account')}?
                    <Link to="/welcome" className="link-no-underline"><span style={{ color: '#E85C33' }}> {translate('Login Now')}</span></Link>
                  </div>
                </Form>
                {/* {
                    passwordvalid1  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'5px'}}>Password and Confirm Password mismatch</p> 
                }
                {
                    invalidCredentails  ? false : <p style={{color:'red',fontSize:'12px',marginTop:'5px'}}>{invalidvalue}</p> 
                } */}
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  );
}
