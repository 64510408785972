import React from 'react';


const NotFound = () => {
  return (
    <div>
     
      <div className="text-center">
      <img src="oopss.jpeg" alt="Smiley" className="text-center"style={{width:"1000px"}}/>
      </div>
    </div>
  );
};

export default NotFound;
