import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation, LanguageProvider } from './LanguageContext';
import './custom.css'


const NavbarLogin = () => {
  const brandStyle = {
    color: '#303A5B',
    fontWeight: 'bold',
    fontSize: '25px'
  };
  const appStyle = {
    color: '#F17C38',
    fontWeight: 'bold',
    fontSize: '25px'
  };
  const cart = {
    color: '#F17C38'
  };

  const { translate, changeLanguage, selectedLanguage } = useTranslation();


  const handleLanguageChange = (lang) => {
    changeLanguage(lang);
    // setSelectedLanguage(lang);  
  };

  const languageTitles = {
    en: 'English',
    es: '中文',
    ma: 'Malaysian'
  };

  const dropdownTitle = languageTitles[selectedLanguage] || '';

  return (
    <>

      <Container>
        <Navbar className="bg-body-tertiary mt-3">
          <Link to="/" className="link-no-underline">
            <Navbar.Brand href="#home" style={brandStyle}>
              <img src={process.env.PUBLIC_URL + '/Icon-512.png'} style={{ width: '80px', height: '80px' }} />
              {/* {translate('Canteen')}
 <span style={appStyle}>                  {translate('App')}
</span> */}
            </Navbar.Brand>
          </Link>
          <Nav className="me-auto"></Nav>

          <Nav>
            <NavDropdown
              title={dropdownTitle}
              className="custom-dropdown"  // Add a custom class
            >
              <NavDropdown.Item onClick={() => handleLanguageChange('en')}>English</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => handleLanguageChange('es')}>中文</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <div>

          </div>
        </Navbar>
      </Container>

    </>
  );
};
// 中文
export default NavbarLogin;
